<template>
  <b-btn
    class="d-flex align-items-center"
    :class="{ icon: onlyIcon, 'justify-content-between': !onlyIcon }"
    :disabled="loading"
    v-bind="$attrs"
    @click="onClick"
  >
    <font-awesome-icon
      v-if="!loading"
      :icon="icon"
      fixed-width
      :class="{ 'position-relative': onlyIcon, 'position-absolute': !onlyIcon }"
    />
    <font-awesome-icon
      v-else
      :icon="['fas', 'spinner']"
      :spin="loading"
      :class="{ 'position-relative': onlyIcon, 'position-absolute': !onlyIcon }"
    />
    <span v-if="!onlyIcon" class="mx-auto pl-1"><slot></slot></span>
  </b-btn>
</template>
<script>
export default {
  props: {
    icon: {
      type: Array,
      required: true,
    },
    onlyIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick(event) {
      if (!this.$el.href) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  &:not(.icon) {
    padding-left: 30px;
    padding-right: 25px;
    @media (max-width: 575.98px) {
      padding-left: 20px;
      padding-right: 10px;
    }
  }
  &.icon {
    display: flex;
    justify-content: center;
  }
  position: relative;
  .position-absolute {
    left: 7px;
  }
  @media (max-width: 575.98px) {
    font-size: 12px;
    padding: 0.25rem 0.25rem;
  }
}
</style>
