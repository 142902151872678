<template>
  <footer class="border-top">
    <portal-target name="footer-top" slim />
    <portal-target name="footer" slim>
      <b-container class="px-0">
        <b-navbar class="px-0">
          <b-navbar-nav class="w-100 p-0">
            <div class="d-flex w-100">
              <b-link
                :to="{ name: 'list' }"
                class="flex-fill text-center d-flex flex-column align-items-center justify-content-center"
              >
                <img src="../assets/img/mdj-sm.svg" height="25" />
                <p class="mb-0">Liste</p>
              </b-link>
              <b-link
                :to="{ name: 'map' }"
                class="flex-fill text-center d-flex flex-column align-items-center justify-content-center"
              >
                <font-awesome-icon :icon="mapIcons" size="lg" />
                <p class="mb-0">Plan</p>
              </b-link>
              <b-link
                :to="{ name: 'bookmarks' }"
                class="flex-fill text-center d-flex flex-column align-items-center justify-content-center"
              >
                <font-awesome-icon :icon="bookmarksIcons" size="lg" />
                <p class="mb-0">Favoris</p>
              </b-link>
              <b-link
                :to="{ name: 'discover' }"
                class="flex-fill text-center d-flex flex-column align-items-center justify-content-center"
              >
                <font-awesome-icon :icon="discoverIcons" size="lg" />
                <p class="mb-0">Découvrir</p>
              </b-link>
              <b-link
                target="_blank"
                class="flex-fill text-center d-flex flex-column align-items-center justify-content-center"
                @click.prevent="$root.$emit('search')"
              >
                <font-awesome-icon icon="search" size="lg" />
                <p class="mb-0">Recherche</p>
              </b-link>
            </div>
          </b-navbar-nav>
        </b-navbar>
      </b-container>
    </portal-target>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  computed: {
    mapIcons: function () {
      return this.$route.name === "map" ? ["fas", "map"] : ["far", "map"]
    },
    bookmarksIcons: function () {
      return this.$route.name === "bookmarks"
        ? ["fas", "heart"]
        : ["far", "heart"]
    },
    discoverIcons: function () {
      return this.$route.name === "discover" ? ["fas", "star"] : ["far", "star"]
    }
  }
}
</script>
