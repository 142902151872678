<template>
  <login-forms
    @login="(token, wasRecentlyCreated) => onLogin(token, wasRecentlyCreated)"
  />
</template>

<script>
import LoginForms from "./LoginForms"
export default {
  components: {
    LoginForms
  },
  methods: {
    onLogin(token, wasRecentlyCreated) {
      this.$root.$emit("LoginSuccessful")
    }
  }
}
</script>
