<template>
  <div>
    <b-container class="h-100 d-flex flex-column p-0">
      <b-form class="p-2" @submit.prevent>
        <b-form-group label-for="venue_name">
          <b-form-input
            id="venue_name"
            ref="inputSearch"
            v-model="venueName"
            class="form-control form-control-lg border-secondary rounded-pill"
            type="text"
            placeholder="Nom du restaurant recherché ?"
            autofocus
            @input="
              reset()
              debounceSearch()
            "
            @blur="$emit('blur')"
            @focus="$emit('focus')"
          />
        </b-form-group>
      </b-form>
      <div v-if="searchResult" class="overflow-auto">
        <div v-if="providerSearch" class="p-2">
          Dans quelle ville se trouve votre restaurant ?

          <mdj-city-selector
            :show-radius="false"
            :update-store="false"
            @input="onInput"
            @clear="onCityClear"
          />
        </div>
        <div class="p-2">
          <b-list-group
            v-if="
              !this.$apollo.loading &&
              searchVenuesProvider.length &&
              venueName.length > 0 &&
              providerSearch
            "
          >
            <b-list-group-item
              v-for="venue in searchVenuesProvider"
              :key="venue.id"
              tag="a"
              href="#"
              class="border-bottom m-0 rounded-0"
              @click.prevent="addVenue(venue)"
            >
              {{ venue.name }}
              <small class="d-block">{{ venue.address }}</small>
            </b-list-group-item>
          </b-list-group>
          <b-list-group
            v-else-if="
              !this.$apollo.loading &&
              searchVenues.length &&
              venueName.length > 0
            "
          >
            <b-list-group-item
              v-for="venue in searchVenues"
              :key="venue.id"
              class="border-bottom m-0 rounded-0"
              tag="a"
              :to="{ name: 'venue', params: { id: venue.id } }"
            >
              <b>{{ venue.name }}</b>
              <small class="d-block">
                {{ venue.address1 }} {{ venue.zipcode }} {{ venue.city }}
              </small>
            </b-list-group-item>
          </b-list-group>
          <div
            v-else-if="
              !this.$apollo.loading &&
              !searchVenuesProvider.length &&
              providerSearch &&
              firstSearchProvider &&
              venueName.length > 0
            "
            class="p-2"
          >
            <b-alert show variant="info">
              Aucun établissement ne correspont à votre recherche
            </b-alert>
          </div>
          <div
            v-if="!$apollo.loading && venueNameLength > 0 && !providerSearch"
            class="p-2"
          >
            <b-btn variant="primary" @click="searchProvider(false)">
              Mon restaurant n'est pas dans la liste
            </b-btn>
          </div>
        </div>
      </div>
      <fit-height-loader
        v-if="$apollo.loading || loading"
        size="1x"
        class="py-2"
      />
    </b-container>
  </div>
</template>

<script>
import SEARCH_VENUE from "@/graphql/SearchVenue.gql"
import VENUE_PROVIDER from "@/graphql/mutations/VenueProvider.gql"
import SEARCH_VENUE_PROVIDER from "@/graphql/SearchVenueProvider.gql"
import FitHeightLoader from "@/components/FitHeightLoader"
import _debounce from "lodash/debounce"
import { mapState } from "vuex"
import MDJCitySelector from "@/components/CitySelector"
export default {
  components: {
    FitHeightLoader,
    "mdj-city-selector": MDJCitySelector
  },
  mixins: [location],
  props: {
    searching: {
      type: Boolean
    },
    searchResult: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      allFilters: false,
      venueName: "",
      venueNameLength: 0,
      searchVenues: [],
      searchVenuesProvider: [],
      providerSearch: false,
      showSearchResult: true,
      firstSearchProvider: false,
      localCoordinate: {
        lat: null,
        lng: null
      }
    }
  },
  apollo: {
    searchVenues: {
      query: SEARCH_VENUE,
      skip: true,
      variables() {
        return {
          search: this.venueName,
          coordinate: {
            lat: this.localCoordinate.lat,
            lng: this.localCoordinate.lng
          }
        }
      }
    },
    searchVenuesProvider: {
      query: SEARCH_VENUE_PROVIDER,
      skip: true,
      variables() {
        return {
          search: this.venueName,
          coordinate: {
            lat: this.localCoordinate.lat,
            lng: this.localCoordinate.lng
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      coordinate: state => state.coordinate
    })
  },
  watch: {
    $route(to, from) {
      this.cancel()
    }
  },
  created() {
    this.debounceSearch = _debounce(this.search, 1500)
    this.localCoordinate.lat = this.coordinate.lat
    this.localCoordinate.lng = this.coordinate.lng
  },
  methods: {
    search() {
      this.venueNameLength = this.venueName.length
      const query = this.providerSearch
        ? "searchVenuesProvider"
        : "searchVenues"
      if (this.providerSearch) {
        this.firstSearchProvider = true
      }
      if (this.venueName && this.venueName.length > 2) {
        this.$apollo.queries[query].skip = false
        this.$apollo.queries[query].refetch().finally(() => {
          this.$refs["inputSearch"].blur()
          this.$apollo.queries[query].skip = true
        })
      } else {
        this.searchVenues = []
        this.$apollo.queries[query].skip = true
      }
    },
    addVenue(venueProvider) {
      this.$apollo
        .mutate({
          mutation: VENUE_PROVIDER,
          variables: {
            id: venueProvider.providerItemId,
            providerName: venueProvider.providerName
          }
        })
        .then(response => {
          this.$router.push({
            name: "venue",
            params: { id: response.data.venueProvider.id }
          })
        })
        .catch(() => {
          alert("Error")
        })
    },
    searchProvider() {
      this.searchVenues = []
      this.providerSearch = true
      this.firstSearchProvider = false
      window.scrollTo(0, 0)
    },
    cancel() {
      this.venueName = ""
      this.providerSearch = false
      this.$apollo.queries.searchVenues.skip = true
      this.$apollo.queries.searchVenuesProvider.skip = true
      this.$emit("cancel")
    },
    onInput(lat, lng) {
      this.$apollo.queries.searchVenues.skip = true
      this.$apollo.queries.searchVenuesProvider.skip = true
      this.localCoordinate.lat = lat
      this.localCoordinate.lng = lng
      this.search()
    },
    reset() {
      this.localCoordinate.lat = this.coordinate.lat
      this.localCoordinate.lng = this.coordinate.lng
      this.providerSearch = false
      this.firstSearchProvider = false
      this.searchVenues = []
      this.searchVenuesProvider = []
    },
    onCityClear() {
      this.searchVenues = []
      this.searchVenuesProvider = []
      this.firstSearchProvider = false
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  border: 0;
  font-size: 14px;
}

#venue_name {
  font-size: 19px;
}
</style>
