<template>
  <div class="h-100 w-100 d-flex align-items-center justify-content-center">
    <loader :size="size" />
  </div>
</template>

<script>
import Loader from "@/components/Loader";
export default {
  components: {
    Loader,
  },
  props: {
    size: {
      type: String,
      required: false,
      default: "2x",
    },
  },
};
</script>
