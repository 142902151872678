import Vue from "vue"
import Vuex from "vuex"
import { Stats } from "fs"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    coordinate: {
      lat: 0,
      lng: 0
    },
    radius: 25,
    shareVenue: null,
    dealVenue: null,
    bookmarkVenue: null,
    callVenue: null,
    bookVenue: {},
    discoverVenue: null,
    appReady: false,
    page: 1,
    fullScreenMedia: [],
    fullScreenMediaIndex: 0,
    city: null,
    noDeal: false,
    layer: null,
    target: null
  },
  mutations: {
    coordinate(state, payload) {
      state.coordinate.lat = payload.lat
      state.coordinate.lng = payload.lng
    },
    radius(state, radius) {
      state.radius = radius
    },
    shareVenue(state, venue) {
      state.shareVenue = venue
    },
    dealVenue(state, venue) {
      state.dealVenue = venue
    },
    bookmarkVenue(state, venue) {
      state.bookmarkVenue = venue
    },
    callVenue(state, venue) {
      state.callVenue = venue
    },
    bookVenue(state, payload) {
      state.bookVenue = {
        venue: payload.venue,
        at: payload.at,
        time: payload.time
      }
    },
    discoverVenue(state, venue) {
      state.discoverVenue = venue
    },
    appReady(state, value) {
      state.appReady = value
    },
    nextPage(state) {
      state.page++
    },
    firstPage(state) {
      state.page = 1
    },
    fullScreenMedia(state, payload) {
      state.fullScreenMedia = payload.media
      state.fullScreenMediaIndex = payload.index
    },
    city(state, value) {
      state.city = value
    },
    noDeal(state, value) {
      state.noDeal = value
    },
    layer(state, value) {
      state.layer = value
    },
    target(state, value) {
      state.target = value
    }
  },
  actions: {}
})
