<template>
  <div id="app" class="h-100">
    <transition name="fade">
      <router-view />
    </transition>
    <full-screen-media :index="fullScreenMediaIndex" :media="fullScreenMedia" />
    <login-modal id="loginForm" ref="loginForm" />
    <layer-modal v-if="layer" id="layerModal" ref="layerModal" />
    <portal-target
      name="page"
      class="h-100 position-absolute w-100"
      style="overflow: auto; top: 0; left: 0"
      slim
    />
    <portal-target
      name="splash"
      class="h-100 position-absolute w-100"
      style="overflow: auto; top: 0; left: 0; z-index: 2000"
      slim
    />
  </div>
</template>
<script>
import FullScreenMedia from "@/components/FullScreenMedia"
import { mapState } from "vuex"
import LAYER_QUERY from "@/graphql/Layer.gql"
import { LayerModal } from "@/components/modals"

export default {
  components: {
    FullScreenMedia,
    LayerModal
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    layer: {
      query: LAYER_QUERY,
      result({ data, loading, networkStatus }) {
        let layerName = null
        if (data.layer) {
          layerName = "show_" + data.layer.name
        }
        if (!localStorage[layerName]) {
          this.$store.commit("layer", data.layer)
        }
      }
    }
  },
  computed: {
    ...mapState({
      fullScreenMedia: state => state.fullScreenMedia,
      fullScreenMediaIndex: state => state.fullScreenMediaIndex
    })
  },
  created() {
    if (localStorage.user_token) {
      this.$store.commit("loginModule/authenticated", true)
    }
  }
}
</script>
