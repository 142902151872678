<template>
  <b-btn
    v-if="venue.activeDeals.length"
    variant="outline-primary"
    size="sm"
    class="rounded-pill"
    @click.prevent="deal"
  >
    <font-awesome-icon icon="gift" fixed-width />
    Obtenir {{ venue.activeDeals[0].price }}€
  </b-btn>
</template>
<script>
import autoAction from "@/mixins/autoAction";
export default {
  mixins: [autoAction],
  props: {
    venue: {
      type: Object,
      required: true,
    },
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    doAction() {
      this.deal();
    },
    deal() {
      if (this.redirect) {
        this.$router.push({ path: "/venue/" + this.venue.id });
      } else {
        this.$store.commit("dealVenue", this.venue);
      }
    },
  },
};
</script>
