<template>
  <b-modal
    v-if="venue"
    id="discoverModal"
    ref="discoverModalRef"
    centered
    modal-class="venue-item"
    :hide-header="true"
    hide-footer
    @hidden="onHidden"
  >
    <mdj-discover
      v-if="venue"
      :venue="venue"
      @like="onLike"
      @dislike="onDislike"
      @pass="onPass"
    />
    <fit-height-loader v-else />
  </b-modal>
</template>
<script>
import MDJDiscover from "@/components/Discover"
import VENUE_DISCOVER from "@/graphql/VenueDiscover.gql"
import FitHeightLoader from "@/components/FitHeightLoader"
import _extend from "lodash/extend"
export default {
  name: "DiscoverModal",
  components: {
    FitHeightLoader,
    "mdj-discover": MDJDiscover
  },
  props: {
    venue: {
      type: Object,
      required: false,
      default: null
    }
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    discoverVenue: {
      query: VENUE_DISCOVER,
      skip: true,
      update(data) {
        _extend(this.venue, data.venue)
        return data.venue
      }
    }
  },
  watch: {
    venue: function() {
      if (this.venue && this.venue.id) {
        this.$nextTick(() => {
          this.$apollo.queries.discoverVenue.refetch({
            id: this.venue.id
          })
          this.$apollo.queries.discoverVenue.skip = false
          this.$refs.discoverModalRef.show()
        })
      }
    }
  },
  methods: {
    onHidden() {
      this.$nextTick(() => {
        this.$store.commit("discoverVenue", null)
      })
    },
    close() {
      this.$refs.discoverModalRef.hide()
    },
    onLike() {
      this.$set(this.venue, "discoverDislike", null)
      this.close()
    },
    onDislike() {
      this.$set(this.venue, "discoverLike", null)
      this.close()
    },
    onPass() {
      this.close()
    }
  }
}
</script>
