<template>
  <b-btn size="sm" class="flat" :disabled="loading" @click.prevent="discover">
    <font-awesome-layers class="fa-2x">
      <font-awesome-icon icon="circle" :class="text" fixed-width />
      <font-awesome-icon
        :icon="icon"
        :class="textIcon"
        :spin="loading"
        transform="shrink-6"
        fixed-width
      />
    </font-awesome-layers>
  </b-btn>
</template>
<script>
import autoAction from "@/mixins/autoAction";
export default {
  mixins: [autoAction],
  props: {
    venue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    text: function () {
      if (this.venue.discoverLike) {
        return "text-primary";
      } else if (this.venue.discoverDislike) {
        return "text-muted";
      } else {
        return "";
      }
    },
    textIcon: function () {
      if (this.venue.discoverLike) {
        return "text-white";
      } else if (this.venue.discoverDislike) {
        return "text-white";
      } else {
        return "text-primary";
      }
    },
    icon: function () {
      if (this.loading) {
        return ["fas", "spinner"];
      } else if (this.venue.discoverLike) {
        return ["fas", "check"];
      } else if (this.venue.discoverDislike) {
        return ["fas", "times"];
      } else {
        return ["far", "star"];
      }
    },
  },
  created() {},
  methods: {
    doAction() {
      this.discover();
    },
    discover() {
      this.$store.commit("discoverVenue", this.venue);
    },
  },
};
</script>
