<template>
  <btn-with-icon
    variant="primary"
    :icon="['fas', 'phone']"
    :only-icon="icon"
    v-bind="$attrs"
    class="rounded-pill py-1 text-uppercase"
    @click="call"
  >
    <span v-if="!icon">Appeler</span>
  </btn-with-icon>
</template>
<script>
import BtnWithIcon from "@/components/buttons/WithIcon";
import autoAction from "@/mixins/autoAction";
export default {
  components: {
    BtnWithIcon,
  },
  mixins: [autoAction],
  props: {
    venue: {
      type: Object,
      required: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    doAction() {
      this.call();
    },
    call() {
      this.$store.commit("callVenue", this.venue);
    },
  },
};
</script>
