<template>
  <b-modal
    id="layerModalRef"
    ref="layerModalRef"
    v-model="show"
    centered
    hide-footer
    @hidden="valid"
  >
    <template v-slot:modal-header="{ close }">
      <div class="d-flex justify-content-end w-100 position-absolute">
        <b-button size="sm" variant="secondary" @click="close()">
          <font-awesome-icon icon="times"></font-awesome-icon>
        </b-button>
      </div>
    </template>
    <div
      class="d-flex flex-column justify-content-between px-3 pb-3 px-md-5 pb-md-5"
    >
      <div class="text-center mb-3 mb-md-5">
        <b-img-lazy v-if="layer.image" :src="layer.image" />
      </div>

      <div class="text-center" v-html="layer.text"></div>
      <b-btn
        v-if="target"
        :href="target"
        btn-block
        variant="primary"
        target="_blank"
      >
        {{ layer.textBtn }}
      </b-btn>
      <b-btn
        v-else
        btn-block
        variant="primary"
        @click="$refs['layerModalRef'].hide()"
      >
        {{ layer.textBtn }}
      </b-btn>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  computed: {
    target: function () {
      return this.layer.linkBtn;
    },
  },
  methods: {
    valid() {
      if (this.layer.name) {
        let layerName = "show_" + this.layer.name;
        localStorage.setItem(layerName, 1);
        this.$store.commit("layer", null);
      } else {
        this.$router.push(this.target);
      }
    },
  },
};
</script>

<style lang="scss">
#layerModalRef {
  .modal-header {
    border: 0;
    position: relative;
    > div {
      left: 0;
      top: 0;
      padding: 1rem;
    }
  }
}
</style>
