<template>
  <div
    v-if="media.length"
    class="position-absolute h-100 w-100 p-2 full-screen-media"
  >
    <b-link
      class="position-absolute text-right text-white close-btn"
      @click.prevent="$store.commit('fullScreenMedia', { media: [], index: 0 })"
    >
      <font-awesome-icon icon="times" size="2x" />
    </b-link>
    <carousel
      :per-page="1"
      :navigate-to="index"
      :mouse-drag="false"
      :navigation-enabled="true"
      navigation-next-label="<span class='btn btn-primary rounded-circle'>&gt;</span>"
      navigation-prev-label="<span class='btn btn-primary rounded-circle'>&lt;</span>"
      class="h-100 d-flex flex-column full-screen-carousel"
    >
      <slide
        v-for="(m, i) in media"
        :key="i"
        class="h-100 d-flex align-items-center justify-content-center"
      >
        <img :src="m.original" class="img-fluid" />
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel"
export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    media: {
      type: Array,
      required: true,
      default: function() {
        return []
      }
    },
    index: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
</script>

<style lang="scss">
.full-screen-media {
  z-index: 10020;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  .close-btn {
    right: 10px;
    z-index: 1000;
  }
  .VueCarousel.full-screen-carousel {
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
    .VueCarousel-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .VueCarousel-inner {
        flex: 1;
        height: 100% !important;
      }
    }
    .VueCarousel-dot-container {
      margin-top: 0 !important;
    }
    .VueCarousel-dot {
      margin-top: 0 !important;
    }
  }
}
</style>
