<template>
  <b-modal
    v-if="venue"
    id="bookmarkModal"
    ref="bookmarkModalRef"
    centered
    modal-class="icon-modal"
    @hidden="onHidden"
  >
    <div slot="modal-header">
      <div class="icon-wrapper">
        <font-awesome-icon
          icon="heart"
          class="text-white"
          size="2x"
          fixed-width
        />
      </div>
    </div>
    <div v-if="!venue.userBookmark" class="body-title">Ajout d'un favori</div>
    <div v-else class="body-title">Suppression d'un favori</div>
    <p v-if="!venue.userBookmark" class="text-center">
      Souhaitez vous ajouter ce restaurant à vos favoris ? Vous serez averti de
      des nouveaux menus, ainsi que de ses actualités
    </p>
    <p v-else class="text-center">
      Souhaitez vous supprimer ce restaurant de vos favoris ? Vous ne serez plus
      averti de des nouveaux menus, ainsi que de ses actualités
    </p>
    <div
      slot="modal-footer"
      class="w-100 d-flex justify-content-center align-items-end"
    >
      <b-button
        variant="outline-dark"
        class="rounded-pill text-uppercase mr-4"
        @click="close"
      >
        non
      </b-button>
      <b-button
        variant="primary"
        class="rounded-pill text-uppercase"
        :disabled="loading"
        @click="bookmark"
      >
        oui
        <font-awesome-icon v-if="loading" icon="spinner" fixed-width spin />
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import STORE_BOOKMARK from "@/graphql/mutations/StoreBookmark.gql";
import DESTROY_BOOKMARK from "@/graphql/mutations/DestroyBookmark.gql";
import { Promise, resolve } from "q";
import { setTimeout } from "timers";
import { isAuthenticatedError } from "@/utils";
export default {
  name: "BookmarkModal",
  props: {
    venue: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    venue: function () {
      if (this.venue && this.venue.id) {
        this.$nextTick(() => {
          this.$refs.bookmarkModalRef.show();
        });
      }
    },
  },
  methods: {
    onHidden() {
      this.$nextTick(() => {
        this.$store.commit("bookmarkVenue", null);
      });
    },
    storeBookmark() {
      return new Promise((resolve, reject) => {
        this.$apollo
          .mutate({
            mutation: STORE_BOOKMARK,
            client: "auth",
            variables: {
              venueId: this.venue.id,
            },
          })
          .then((response) => {
            this.$set(this.venue, "userBookmark", {
              id: response.data.storeBookmark.id,
            });
            resolve();
          })
          .catch((error) => {
            if (isAuthenticatedError(error)) {
              return this.$login
                .login()
                .then((response) =>
                  this.storeBookmark()
                    .then(() => resolve())
                    .catch((error) => reject(error))
                )
                .catch((error) => reject(error));
            }
          });
      });
    },
    destroyBookmark() {
      return new Promise((resolve, reject) => {
        this.$apollo
          .mutate({
            mutation: DESTROY_BOOKMARK,
            client: "auth",
            variables: {
              userVenueBookmarksId: this.venue.userBookmark.id,
            },
          })
          .then((response) => {
            this.$set(this.venue, "userBookmark", null);
            resolve();
          })
          .catch((error) => {
            if (isAuthenticatedError(error)) {
              this.$login
                .login()
                .then((response) => {
                  this.destroyBookmark().then((response) => {
                    resolve();
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            }
          });
      });
    },
    bookmark() {
      this.loading = true;
      if (this.venue.userBookmark) {
        this.destroyBookmark()
          .then(() => {
            this.loading = false;
            this.$refs.bookmarkModalRef.hide();
          })
          .catch(() => (this.loading = false));
      } else {
        this.storeBookmark()
          .then(() => {
            this.loading = false;
            this.$refs.bookmarkModalRef.hide();
          })
          .catch(() => (this.loading = false));
      }
    },
    close() {
      this.$refs.bookmarkModalRef.hide();
    },
  },
};
</script>
