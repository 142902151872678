<template>
  <b-modal
    v-if="venue"
    id="bookModal"
    ref="bookModalRef"
    centered
    modal-class="icon-modal"
    hide-footer
    @hidden="onHidden"
  >
    <b-form ref="form" @submit.prevent="book">
      <div slot="modal-header">
        <div class="image-wrapper">
          <b-link
            href="https://mdj.to/tracking?from=webapp-book-modal"
            target="_blank"
          >
            <img src="../../assets/img/du-jour-pro-logo.png" height="66" />
          </b-link>
        </div>
        <p class="text-center" style="font-size: 12px">
          {{
            `${
              preparedTranslations[locale]["welcome"] ||
              "Bienvenue sur la plateforme de réservation DuJour.pro"
            }`
          }}
        </p>

        <div
          v-for="(flags, i) in chunkFlags"
          :key="i"
          class="d-flex items-center position-relative my-3 justify-content-center"
          style="z-index: 100"
        >
          <div
            v-for="(data, i) in flags || []"
            :key="i"
            @click="setLocale(data)"
            style="cursor: pointer"
            class="text-center cursor-pointer border-transparent"
          >
            <img
              :src="data.flag"
              height="40"
              class="w-full border-transparent p-1"
              :class="{
                'locale-selected border-primary': locale === data.locale,
                'border-white border': locale !== data.locale
              }"
            />
          </div>
        </div>
      </div>
      <h5 class="text-primary text-center mb-3 font-weight-bold">
        {{ `${preparedTranslations[locale]["title"] || "Restaurant"}` }}
        {{ venue.name }} {{ venue.zipcode }} {{ venue.city }}
      </h5>
      <p
        v-if="venue.venueServiceDescription"
        class="my-3"
        v-html="venue.venueServiceDescription"
      ></p>
      <template v-if="!reservation">
        <b-alert
          v-if="upcomingBooking && upcomingBooking.length > 0"
          variant="warning"
          show
        >
          {{ upcomingBooking.length }} réservation à venir dans cet
          établissement
          <p v-for="item in upcomingBooking" :key="item.id">
            Le {{ item.at.localeFormat }} pour
            {{ item.guest }} personne(s)
          </p>
        </b-alert>
        <div>
          <b-form-group
            :label="
              preparedTranslations[locale]['labels.date'] ||
              'Date de réservation désirée : '
            "
            invalid-feedback="La date est obligatoire"
          >
            <b-form-datepicker
              v-model="date"
              class="mb-2"
              required
              :state="errors && errors['date'] ? false : null"
              :min="minDate"
              :date-disabled-fn="disableDate"
              :date-info-fn="dateInfo"
              :start-weekday="1"
              :locale="locale"
              @input="fetch"
            ></b-form-datepicker>
          </b-form-group>
          <template
            v-if="availableOnlineBooking && availableOnlineBooking.available"
          >
            <b-row>
              <b-form-group
                :label="`${
                  preparedTranslations[locale]['labels.first_name'] ||
                  'Votre prénom : '
                }*`"
                :invalid-feedback="`${
                  preparedTranslations[locale]['errors.first_name'] ||
                  'Le champ prénom est obligatoire'
                }`"
                class="col"
                required
              >
                <b-form-input
                  v-model="firstName"
                  type="text"
                  required
                  :state="errors && errors['firstName'] ? false : null"
                  :invalid-feedback="`${
                    preparedTranslations[locale]['errors.first_name'] ||
                    'Le champ prénom est obligatoire'
                  }`"
                />
              </b-form-group>
              <b-form-group
                :label="`${
                  preparedTranslations[locale]['labels.name'] || 'Votre nom : '
                }*`"
                :invalid-feedback="`${
                  preparedTranslations[locale]['errors.name'] ||
                  'Le champ nom est obligatoire'
                }`"
                class="col"
                required
              >
                <b-form-input
                  v-model="lastName"
                  type="text"
                  required
                  :state="errors && errors['lastName'] ? false : null"
                  :invalid-feedback="`${
                    preparedTranslations[locale]['errors.name'] ||
                    'Le champ nom est obligatoire'
                  }`"
                />
              </b-form-group>
            </b-row>
            <b-form-group
              :label="`${
                preparedTranslations[locale]['labels.company'] ||
                'Votre entreprise (facultatif) :'
              }`"
            >
              <b-form-input
                v-model="name"
                type="text"
                :state="errors && errors['name'] ? false : null"
              />
            </b-form-group>

            <b-form-group
              v-if="(me && !me.email) || !me"
              :label="`${
                preparedTranslations[locale]['labels.email'] || 'Email : '
              }*`"
              :invalid-feedback="`${
                preparedTranslations[locale]['errors.email'] ||
                'Le champ email est obligatoire'
              }`"
            >
              <b-form-input
                v-model="email"
                type="email"
                required
                :state="errors && errors['email'] ? false : null"
                :invalid-feedback="`${
                  preparedTranslations[locale]['errors.email'] ||
                  'Le champ email est obligatoire'
                }`"
              />
            </b-form-group>
            <input-phone
              v-if="!phone || !me"
              v-model="phone"
              :translations="preparedTranslations[locale]"
              ref="phoneInput"
              :state="errors && errors['phone'] ? false : null"
              :invalid-feedback="`${
                preparedTranslations[locale]['errors.phone'] ||
                'Le numéro téléphone est incorrect'
              }`"
              :label="`${
                preparedTranslations[locale]['labels.phone'] ||
                'Nº de mobile : '
              }*`"
              required
              @loading="l => (loading = l)"
            />
            <h6>
              {{
                `${
                  preparedTranslations[locale]["labels.hour"] ||
                  "Heures d'arrivée :"
                }`
              }}
            </h6>
            <div
              v-if="!$apollo.loading && availableOnlineBooking"
              class="d-flex justify-content-between"
            >
              <div class="row w-100 flex-wrap">
                <div
                  v-for="(hour, i) in availableOnlineBooking.hours"
                  :key="i"
                  class="col-4"
                >
                  <label
                    :for="'hour_' + hour.time"
                    class="btn px-2 btn-outline-secondary d-flex align-items-center justify-content-center radio-label w-100"
                    :class="{
                      'bg-primary text-white':
                        pickedHour && pickedHour.time === hour.time
                    }"
                  >
                    <input
                      :id="'hour_' + hour.time"
                      v-model="pickedHour"
                      type="radio"
                      name="hour"
                      :value="hour"
                      required
                      :state="errors && errors['pickedHour'] ? false : null"
                    />
                    {{ hour.time }}
                  </label>
                </div>
              </div>
            </div>
            <p v-if="errors['pickedHour']" class="text-danger">
              {{
                `${
                  preparedTranslations[locale]["errors.hour"] ||
                  "L'heure d'arrivée est obligatoire"
                }`
              }}
            </p>
            <p class="mt-3 mb-2">
              {{
                `${
                  preparedTranslations[locale]["labels.guest"] ||
                  "Nombre de couvert(s) :"
                }`
              }}
            </p>
            <div class="row justify-content-start">
              <div v-for="i in guestsValues" :key="i" class="col-4">
                <label
                  :for="'nb_guests_' + i"
                  :class="{ 'bg-primary text-white': guest == i }"
                  class="btn btn-outline-secondary d-flex align-items-center justify-content-center radio-label"
                >
                  <input
                    :id="'nb_guests_' + i"
                    v-model="guest"
                    type="radio"
                    name="guest"
                    :value="i"
                  />
                  {{ i }}
                </label>
              </div>
              <div class="col-4">
                <b-btn
                  v-if="shouldMoreGuests"
                  variant="outline-secondary w-100 d-block radio-label"
                  @click="onMoreGuestClick"
                >
                  <span v-if="!moreGuest">+</span>
                  <span v-else>-</span>
                </b-btn>
              </div>
              <div
                v-if="moreGuest && shouldMoreGuests"
                class="w-100 d-flex align-items-center justify-content-center"
              >
                <label class="mb-0 mr-2">
                  {{
                    `${
                      preparedTranslations[locale]["labels.more-guest"] ||
                      "Nb de personnes"
                    }`
                  }}
                </label>
                <b-input
                  v-if="moreGuest"
                  v-model="guest"
                  style="height: 45px; width: 100px"
                  name="guest"
                  type="number"
                  placeholder=""
                  min="0"
                  :max="
                    pickedHour && pickedHour.maxGuests
                      ? pickedHour.maxGuests
                      : 100
                  "
                  :state="errors && errors['guest'] ? false : null"
                />
              </div>
            </div>
            <p v-if="errors['guest'] || errors['guest']" class="text-danger">
              {{
                `${
                  preparedTranslations[locale]["errors.guest"] ||
                  "Ce champs est obligatoire"
                }`
              }}
            </p>
            <h6>
              {{
                `${
                  preparedTranslations[locale]["labels.comment"] ||
                  "Commentaires :"
                }`
              }}
            </h6>
            <b-form-textarea
              id="textarea"
              v-model="comment"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </template>
          <b-alert
            v-else-if="
              availableOnlineBooking && !availableOnlineBooking.available
            "
            show
          >
            {{
              `${
                preparedTranslations[locale]["unavailable"] ||
                "Pas de réservation disponible. Choisir un autre jour."
              }`
            }}
          </b-alert>
        </div>
      </template>
      <template v-else>
        <div v-if="reservation.needImprint">
          <div>
            <p
              v-html="
                replace(preparedTranslations[locale]['imprint'], {
                  date: reservation.at.localeFormat,
                  name: reservation.name,
                  guest: reservation.guest
                })
              "
            ></p>
          </div>
        </div>
        <div v-else-if="reservation.acceptedAt">
          <p
            v-html="
              replace(preparedTranslations[locale]['accepted'], {
                date: reservation.at.localeFormat,
                name: reservation.name,
                guest: reservation.guest
              })
            "
          ></p>
          <p class="mb-3" v-html="preparedTranslations[locale]['spam']"></p>
        </div>
        <div v-else-if="!reservation.acceptedAt">
          <p
            v-html="
              replace(preparedTranslations[locale]['pending'], {
                date: reservation.at.localeFormat,
                name: reservation.name,
                guest: reservation.guest
              })
            "
          ></p>
          <p class="mb-3" v-html="preparedTranslations[locale]['spam']"></p>
        </div>
      </template>
      <div
        slot="modal-footer"
        class="w-100 d-flex flex-column justify-content-end align-items-center"
      >
        <template v-if="!reservation">
          <template
            v-if="availableOnlineBooking && availableOnlineBooking.available"
          >
            <b-button
              variant="primary"
              class="rounded-pill text-uppercase"
              :disabled="loading"
              :loading="loading"
              type="submit"
            >
              {{
                `${
                  preparedTranslations[locale]["btns.submit"] ||
                  "Envoyer la demande de réservations"
                }`
              }}

              <font-awesome-icon
                v-if="loading"
                icon="spinner"
                fixed-width
                spin
              />
            </b-button>
            <small
              class="d-block text-center mt-2 mx-5"
              style="font-size: 10px"
            >
              {{
                `${
                  preparedTranslations[locale]["privacy"] ||
                  "En cliquant sur ce bouton, vous confirmez accepter de recevoir des emails de cet établissement."
                }`
              }}
            </small>
          </template>
          <call-btn v-else :venue="venue" block :icon="false" />
        </template>
        <template v-else>
          <b-button
            v-if="availableOnlineBooking && availableOnlineBooking.available"
            variant="primary"
            class="rounded-pill text-uppercase"
            @click="$refs['bookModalRef'].hide()"
          >
            OK
          </b-button>
        </template>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import AVAILABLE_ONLINE_BOOKING from "@/graphql/AvailableOnlineBooking.gql"
import UPCOMING_BOOKING from "@/graphql/auth/upcomingBooking.gql"
import STORE_ONLINE_BOOKING from "@/graphql/mutations/StoreOnlineBooking.gql"
import DISABLED_BOOKING_DATE from "@/graphql/DisabledBookingDate.gql"
import RESERVATION_TRANSLATION_LOCALES from "@/graphql/ReservationTranslationLocales.gql"
import moment from "moment"
import Me from "@/mixins/me"
import { CallBtn } from "@/components/buttons"
import { InputPhone } from "@/components"
import { mapState } from "vuex"
import { first } from "@/utils"
import _chunk from "lodash/chunk"
export default {
  name: "BookModal",
  components: {
    CallBtn,
    InputPhone
  },
  mixins: [Me],
  props: {
    venue: {
      type: Object,
      required: false,
      default: null
    },
    at: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      date: moment().format("YYYY-MM-DD"),
      pickedHour: null,
      name: "",
      comment: null,
      nameState: null,
      errors: {},
      guest: null,
      reservation: null,
      moreGuest: false,
      phone: null,
      email: null,
      firstName: null,
      lastName: null,
      minDate: new Date(),
      locale: "fr"
    }
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    disabledBookingDate: {
      query: DISABLED_BOOKING_DATE,
      skip: true,
      variables: {
        venueId: null
      }
    },
    // Simple query that will update the 'hello' vue property
    reservationTranslationLocales: {
      query: RESERVATION_TRANSLATION_LOCALES
    },
    availableOnlineBooking: {
      query: AVAILABLE_ONLINE_BOOKING,
      skip: true,
      variables: {
        venueId: null,
        date: null
      }
    },
    upcomingBooking: {
      query: UPCOMING_BOOKING,
      client: "auth",
      skip: true,
      variables: {
        venueId: null
      }
    }
  },
  computed: {
    ...mapState({
      bookVenue: state => state.bookVenue
    }),
    chunkFlags: function () {
      if (this.reservationTranslationLocales) {
        var res = _chunk(this.reservationTranslationLocales, 6)
        return res
      } else {
        return []
      }
    },
    preparedTranslations: function () {
      let arr = []
      this.reservationTranslationLocales.forEach(locale => {
        let o = {}
        locale.translations.forEach(value => {
          o[value.key] = value.value
        })
        arr[locale.locale] = o
      })
      return arr
    },
    shouldMoreGuests: function () {
      if (this.pickedHour && this.pickedHour.maxGuests) {
        return parseInt(this.pickedHour.maxGuests, 10) > 9
      }
      return true
    },
    guestsValues: function () {
      const arr = []
      const max = Math.min(
        this.pickedHour && this.pickedHour.maxGuests
          ? this.pickedHour.maxGuests
          : 9,
        9
      )
      for (let i = 1; i <= max; i++) {
        arr.push(i)
      }
      return arr
    }
  },
  watch: {
    venue: function () {
      if (this.venue && this.venue.id) {
        this.$nextTick(() => {
          this.$refs.bookModalRef.show()
          const at = moment(this.bookVenue.at, "DD-MM-YYYY")
          if (at.isValid()) {
            const moments = [at, moment(this.date)]
            this.date = moment.max(moments).format("YYYY-MM-DD")
          }
          this.fetch()
        })
      }
    },
    pickedHour: function () {
      this.guest = null
    },
    me: function () {
      this.phone = this.me.phone
      this.email = this.me.email
      this.firstName = this.me.firstName
      this.lastName = this.me.name
    }
  },
  mounted() {},
  methods: {
    onHidden() {
      this.$nextTick(() => {
        this.reset()
        this.$store.commit("bookVenue", {})
      })
    },
    book() {
      this.errors = {}
      let fields = [
        "date",
        "pickedHour",
        "guest",
        "email",
        "phone",
        "lastName",
        "firstName"
      ]
      fields.forEach(f => {
        if (this[f] === null || this[f] === "") {
          this.errors[f] = "Ce champ est obligatoire"
        }
      })
      console.log(this.errors)

      if (!Object.keys(this.errors).length) {
        this.store()
      }
    },
    store() {
      let date = moment(this.date).add(moment.duration())
      const info = this.pickedHour.time.split(":")
      date.set({ hour: info[0], minutes: info[1] })
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$apollo
          .mutate({
            mutation: STORE_ONLINE_BOOKING,
            variables: {
              venueId: this.venue.id,
              date: date.format("YYYY-MM-DD HH:mm"),
              guest: parseInt(this.guest, 10),
              comment: this.comment,
              name: [this.name, this.firstName + " " + this.lastName]
                .filter(s => s)
                .join(" - "),
              phone: this.phone,
              email: this.email,
              firstName: this.firstName,
              lastName: this.lastName,
              source: "webapp",
              locale: this.locale
            }
          })
          .then(response => {
            this.reservation = response.data.storeOnlineBooking
            if (response.data.storeOnlineBooking.userToken) {
              this.$login.ready(
                response.data.storeOnlineBooking.userToken,
                false
              )
            }
            if (
              response.data.storeOnlineBooking.needImprint &&
              response.data.storeOnlineBooking.imprintUrl
            ) {
              window.open(response.data.storeOnlineBooking.imprintUrl, "_blank")
            }
            resolve()
          })
          .catch(error => {
            this.errors = {}
            if (error.graphQLErrors) {
              let validation = first(error.graphQLErrors).extensions.validation
              if (validation) {
                for (let k in validation) {
                  this.errors[k] = first(validation[k])
                }
              }
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    close() {
      this.$refs.bookModalRef.hide()
    },
    fetch() {
      this.$apollo.queries.me.skip = false
      this.$apollo.queries.me.refetch().then(response => {})
      this.$apollo.queries.availableOnlineBooking.setVariables({
        venueId: this.venue.id,
        date: moment(this.date).format("YYYY-MM-DD")
      })

      this.$apollo.queries.availableOnlineBooking.skip = false
      this.$apollo.queries.availableOnlineBooking.refetch()
      if (this.authenticated) {
        this.$apollo.queries.upcomingBooking.setVariables({
          venueId: this.venue.id
        })
        this.$apollo.queries.upcomingBooking.skip = false
        this.$apollo.queries.upcomingBooking.refetch()
      }
      this.$apollo.queries.disabledBookingDate.setVariables({
        venueId: this.venue.id
      })

      this.$apollo.queries.disabledBookingDate.skip = false
      this.$apollo.queries.disabledBookingDate.refetch()
    },
    onMoreGuestClick(i) {
      this.moreGuest = !this.moreGuest
      if (!this.moreGuest) {
        this.guest = null
      }
    },
    reset() {
      this.reservation = null
      this.date = moment().format("YYYY-MM-DD")
      this.pickedHour = null
      this.comment = null
      this.guest = null
    },
    disableDate(ymd, date) {
      if (this.disabledBookingDate) {
        const weekday = date.getDay()
        const day = moment(date).format("YYYY-MM-DD")
        // Return `true` if the date should be disabled
        return (
          (this.disabledBookingDate.weekDays.includes(weekday) ||
            this.disabledBookingDate.dates.includes(day)) &&
          !this.disabledBookingDate.openDates.includes(day)
        )
      }
      return true
    },
    dateInfo(ymd, date) {
      return this.disableDate(ymd, date) ? "table-danger" : "table-success"
    },
    replace(string, replacements) {
      let res = string
      for (let k in replacements) {
        res = res.replace(`{${k}}`, replacements[k])
      }
      return res
    },
    setLocale(data) {
      this.locale = data.locale
      if (this.$refs["phoneInput"]) {
        this.$refs["phoneInput"].setCountry(data.country)
      }
    }
  }
}
</script>

<style lang="scss">
#bookModal {
  padding-top: 50px;
  .close {
    top: 20px;
    position: relative;
  }
  .image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    a {
      transform: translateY(-50%);
    }
  }
  .radio-label {
    position: relative;
    height: 45px;
    text-align: center;

    input[type="radio"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  h6 {
    text-align: center;
    margin-bottom: 0;
    margin-top: 0.75rem;
  }

  .b-calendar {
    .b-calendar-grid-body {
      .bg-light {
        background-color: #f5c6cb !important;
      }
    }
  }
  .locale-selected {
    border: 1px solid black;
  }
}
</style>
