<template>
  <div v-if="venue">
    <b-modal
      id="callModal"
      ref="callModalRef"
      :hide-footer="true"
      centered
      modal-class="icon-modal"
      @hidden="onHiddenCall()"
    >
      <div slot="modal-header">
        <div class="icon-wrapper">
          <font-awesome-icon
            icon="phone"
            class="text-white"
            size="2x"
            fixed-width
          />
        </div>
      </div>
      <div class="d-block">
        <b-btn :href="'tel:' + venue.phone" variant="outline-primary" block>
          {{ venue.phone }}
        </b-btn>
        <p class="text-left">Avez vous réservé dans ce restaurant ?</p>
        <h3 class="border rounded py-2 text-center">
          {{ venue.name }}
        </h3>
      </div>
      <div class="mt-5">
        <btn-with-icon
          variant="primary"
          :icon="['fas', 'thumbs-up']"
          :only-icon="false"
          block
          @click="success()"
        >
          Ma réservation a abouti
        </btn-with-icon>
        <b-link
          class="d-flex text-center justify-content-center align-items-center mt-1 px-2"
          @click="failed"
        >
          <font-awesome-icon class="mr-auto" icon="thumbs-down" fixed-width />
          <span class="mr-auto">Ma réservation n'a pas abouti</span>
        </b-link>
      </div>
    </b-modal>
    <b-modal
      id="successCallModal"
      ref="successCallModalRef"
      :hide-footer="true"
      class="icon-modal"
      centered
      @hidden="state = onHiddenSuccess()"
    >
      <div slot="modal-header">
        <div class="icon-wrapper">
          <font-awesome-icon
            icon="check"
            class="text-white"
            size="2x"
            fixed-width
          />
        </div>
      </div>
      <p>Vous avez reservé ici</p>
      <h3 class="border rounded py-2 text-center">
        {{ venue.name }}
      </h3>
      <p class="mt-3 mb-2">Nombre de couvert</p>
      <div class="d-flex justify-content-center flex-wrap">
        <div v-for="(guests, k) in chunkGuestsValues" :key="k" class="d-flex">
          <label
            v-for="i in guests"
            :key="i"
            :for="'nb_guests_' + i"
            class="btn btn-outline-secondary mx-2 d-flex align-items-center justify-content-center radio-label"
            :class="{ 'bg-primary text-white': ngGuests == i }"
          >
            <input
              :id="'nb_guests_' + i"
              v-model="ngGuests"
              type="radio"
              name="guest"
              :value="i"
            />
            {{ i }}
          </label>
        </div>
      </div>
      <btn-with-icon
        variant="primary"
        :icon="['fas', 'thumbs-up']"
        block
        :loading="loading"
        :disabled="ngGuests === null"
        :only-icon="false"
        @click="done()"
      >
        J'ai bien réservé
      </btn-with-icon>
      <b-link class="d-block text-center mt-1" @click="state = 'CALL'">
        Annuler
      </b-link>
    </b-modal>
    <b-modal
      id="failCallModal"
      ref="failCallModalRef"
      :hide-footer="true"
      class="icon-modal"
      centered
      @hidden="onHiddenFail()"
    >
      <div slot="modal-header">
        <div class="icon-wrapper">
          <font-awesome-icon
            icon="times"
            class="text-white"
            size="2x"
            fixed-width
          />
        </div>
      </div>
      <p>Pour quelle raison n'avez-vous finalement pas réservé ?</p>
      <b-btn
        v-for="(reason, i) in reasons"
        :key="i"
        variant="outline-primary"
        block
        @click.prevent="fail(reason)"
      >
        {{ reason }}
      </b-btn>
      <b-link class="d-block text-center mt-1" @click="state = 'CALL'">
        Annuler
      </b-link>
    </b-modal>
  </div>
</template>
<script>
import { chunk } from "@/utils"
import STORE_BOOKING from "@/graphql/mutations/StoreBooking.gql"
import STORE_FAILED_BOOKING from "@/graphql/mutations/StoreFailedBooking.gql"
import BtnWithIcon from "@/components/buttons/WithIcon"
export default {
  name: "CallModal",
  components: {
    BtnWithIcon
  },
  props: {
    venue: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      state: "CALL",
      isSuccess: null,
      guestsValues: [1, 2, 3, 4, 5, 6, 7, "8+"],
      ngGuests: null,
      loading: false,
      login: false,
      isTerminated: false,
      reasons: [
        "Je n'ai pas eu de réponse",
        "Il n'y avait plus de place",
        "J'ai changé d'avis",
        "Ce lieu ne prend pas de réservation"
      ]
    }
  },
  computed: {
    chunkGuestsValues: function () {
      return chunk(this.guestsValues, 4)
    }
  },
  watch: {
    venue: function () {
      if (this.venue && this.venue.id) {
        this.$nextTick(() => {
          this.isTerminated = false
          this.$refs.callModalRef.show()
        })
      }
    },
    state: function () {
      if (!this.login) {
        switch (this.state) {
          case "CALL":
            this.$refs.successCallModalRef.hide()
            this.$refs.failCallModalRef.hide()
            this.$refs.callModalRef.show()
            break
          case "SUCCESS":
            this.$refs.successCallModalRef.show()
            this.$refs.failCallModalRef.hide()
            this.$refs.callModalRef.hide()
            break
          case "FAIL":
            this.$refs.successCallModalRef.hide()
            this.$refs.failCallModalRef.show()
            this.$refs.callModalRef.hide()
            break
          default:
            this.$refs.successCallModalRef.hide()
            this.$refs.failCallModalRef.hide()
            this.$refs.callModalRef.show()
        }
      } else {
        this.$refs.successCallModalRef.hide()
        this.$refs.failCallModalRef.hide()
        this.$refs.callModalRef.hide()
      }
    }
  },
  methods: {
    onHiddenCall() {
      if (this.state === "CALL") {
        this.$store.commit("callVenue", null)
      }
    },
    onHiddenFail() {
      if (this.isTerminated) {
        this.$store.commit("callVenue", null)
      } else {
        this.$nextTick(() => {
          this.state = "CALL"
        })
      }
    },
    onHiddenSuccess() {
      if (this.isTerminated) {
        this.$store.commit("callVenue", null)
      } else {
        this.$nextTick(() => {
          this.state = "CALL"
        })
      }
    },
    failed() {
      this.state = "FAIL"
    },
    success() {
      this.state = "SUCCESS"
    },
    done() {
      this.loading = true
      this.storeBooking()
        .then(() => this.end())
        .catch(() => this.endFail())
    },
    fail(reason) {
      this.loading = true
      this.storeFailedBooking(reason)
        .then(() => this.end())
        .catch(() => this.endFail())
    },
    storeBooking(reason) {
      return new Promise((resolve, reject) => {
        this.$apollo
          .mutate({
            mutation: STORE_BOOKING,
            client: "auth",
            variables: {
              venueId: this.venue.id,
              guest: this.ngGuests
            }
          })
          .then(response => {
            resolve()
          })
          .catch(error => {
            if (
              error.message ===
              "Network error: Response not successful: Received status code 401"
            ) {
              this.login = true
              return this.$login
                .login()
                .then(response =>
                  this.storeBooking(reason)
                    .then(() => resolve())
                    .catch(error => reject(error))
                )
                .catch(error => {
                  reject(error)
                })
            }
          })
      })
    },
    storeFailedBooking(reason) {
      return new Promise((resolve, reject) => {
        this.$apollo
          .mutate({
            mutation: STORE_FAILED_BOOKING,
            client: "auth",
            variables: {
              venueId: this.venue.id,
              reason: reason
            }
          })
          .then(response => {
            resolve()
          })
          .catch(error => {
            if (
              error.message ===
              "Network error: Response not successful: Received status code 401"
            ) {
              this.login = true
              return this.$login
                .login()
                .then(response =>
                  this.storeFailedBooking(reason)
                    .then(() => resolve())
                    .catch(error => reject(error))
                )
                .catch(error => {
                  reject(error)
                })
            }
          })
      })
    },
    end() {
      this.loading = false
      this.login = false
      this.$refs.failCallModalRef.hide()
      this.$refs.successCallModalRef.hide()
      this.state = "CALL"
      this.isTerminated = true
    },
    endFail() {
      this.loading = false
      this.login = false
      this.state = ""
      this.$nextTick(() => {
        this.state = "CALL"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#successCallModal {
  label.radio-label {
    position: relative;
    width: 45px;
    height: 45px;
    text-align: center;
    input[type="radio"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}
</style>
