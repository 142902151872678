<template>
  <btn-with-icon
    variant="primary"
    :icon="['fas', 'calendar']"
    v-bind="$attrs"
    class="rounded-pill py-1 text-uppercase"
    :only-icon="icon"
    @click="book"
  >
    <span v-if="!icon">Réserver</span>
  </btn-with-icon>
</template>
<script>
import BtnWithIcon from "@/components/buttons/WithIcon"
import autoAction from "@/mixins/autoAction"
export default {
  components: {
    BtnWithIcon
  },
  mixins: [autoAction],
  props: {
    venue: {
      type: Object,
      required: true
    },
    icon: {
      type: Boolean,
      default: true
    },
    at: {
      type: String,
      default: null
    },
    time: {
      type: String,
      default: null
    }
  },
  methods: {
    doAction() {
      this.book()
    },
    book() {
      this.$store.commit("bookVenue", {
        venue: this.venue,
        at: this.at,
        time: this.time
      })
    }
  }
}
</script>
