<template>
  <div class="h-100 position-relative d-flex flex-column">
    <div class="flex-fill d-flex flex-column overflow-auto">
      <h2 class="h3">
        <b-link :to="{ path: 'venue/' + venue.id }" append>
          {{ venue.name }}
        </b-link>
        <!-- <small class="d-block" v-if="venue.badge">Abonné</small> -->
      </h2>
      <small class="display font-weight-normal d-block location">
        <font-awesome-icon icon="map-marker-alt" />
        {{ venue.address1 }}
        <br />
        {{ venue.zipcode }} {{ venue.city }} {{ venue.distance }}
      </small>
      <div class="flex-fill">
        <h6 v-if="venue.description" class="mt-2 mb-2">Description</h6>
        <p v-if="venue.description" class="description">
          {{ venue.description }}
        </p>
        <h6 v-if="venue.cuisineSpecificities" class="mt-2 mb-2">Spécialité</h6>
        <p v-if="venue.cuisineSpecificities" class="description">
          {{ venue.cuisineSpecificities }}
        </p>
        <div
          class="cover"
          :style="'background-image:url(' + venue.picture + ')'"
        ></div>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-between align-items-center mt-2">
      <b-button
        variant="light"
        class="rounded-circle"
        @click="storeVenueDislike"
      >
        <font-awesome-icon icon="times" class="text-dark" size="4x" />
      </b-button>
      <b-link @click="$emit('pass')"> Annuler </b-link>
      <b-button
        variant="primary"
        class="rounded-circle"
        @click="storeVenueLike"
      >
        <font-awesome-icon icon="plus" class="text-white" size="4x" />
      </b-button>
    </div>
  </div>
</template>

<script>
import STORE_VENUE_LIKE from "@/graphql/mutations/StoreDiscoverLike.gql";
import STORE_VENUE_DISLIKE from "@/graphql/mutations/StoreDiscoverDislike.gql";
export default {
  props: {
    venue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    storeVenueLike() {
      return new Promise((resolve, reject) => {
        this.$apollo
          .mutate({
            mutation: STORE_VENUE_LIKE,
            client: "auth",
            variables: {
              venueId: this.venue.id,
            },
          })
          .then((response) => {
            this.$set(this.venue, "discoverLike", {
              id: response.data.discoverLike.id,
              __typename: "DiscoverLike",
            });
            this.$emit("like");
            resolve();
          })
          .catch((error) => {
            if (isAuthenticatedError(error)) {
              return this.$login
                .login()
                .then((response) =>
                  this.storeVenueLike()
                    .then(() => resolve())
                    .catch((error) => reject(error))
                )
                .catch((error) => reject(error));
            }
          });
      });
    },
    storeVenueDislike() {
      return new Promise((resolve, reject) => {
        this.$apollo
          .mutate({
            mutation: STORE_VENUE_DISLIKE,
            client: "auth",
            variables: {
              venueId: this.venue.id,
            },
          })
          .then((response) => {
            this.$set(this.venue, "discoverDislike", {
              id: response.data.discoverDislike.id,
              __typename: "DiscoverDislike",
            });
            this.$emit("dislike");
            resolve();
          })
          .catch((error) => {
            if (
              error.message ===
              "Network error: Response not successful: Received status code 401"
            ) {
              return this.$login
                .login()
                .then((response) =>
                  this.storeVenueLike()
                    .then(() => resolve())
                    .catch((error) => reject(error))
                )
                .catch((error) => reject(error));
            }
          });
      });
    },
  },
};
</script>
