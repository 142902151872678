<template>
  <b-modal
    id="loginModal"
    ref="loginModalRef"
    centered
    hide-footer
    @hidden="onHidden"
  >
    <login-forms
      @login="(token, wasRecentlyCreated) => onLogin(token, wasRecentlyCreated)"
    />
  </b-modal>
</template>

<script>
import LoginForms from "./LoginForms";
export default {
  components: {
    LoginForms,
  },
  mounted() {
    this.$root.$on("login", () => {
      this.showLoginForm();
    });
  },
  methods: {
    showLoginForm() {
      this.$nextTick(() => {
        this.isSuccess = false;
        this.$refs.loginModalRef.show();
      });
    },
    onLogin(token, wasRecentlyCreated) {
      this.$root.$emit("LoginSuccessful");
      if (this.$refs.loginModalRef) {
        this.$refs.loginModalRef.hide();
      }
    },
    onHidden() {
      if (!this.isSuccess) {
        this.$root.$emit("LoginCancel");
        this.$emit("cancel");
      }
    },
  },
};
</script>
