import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import BootstrapVue from "bootstrap-vue"
import PortalVue from "portal-vue"
import * as VueGoogleMaps from "vue2-google-maps"
import Vue2TouchEvents from "vue2-touch-events"
import ToggleButton from "vue-js-toggle-button"
import moment from "moment"
import VueLazyload from "vue-lazyload"
import VueI18n from "vue-i18n"

import { mapState } from "vuex"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from "@fortawesome/vue-fontawesome"

import AccountLink from "@/components/AccountLink.vue"
import BtnWithIcon from "@/components/buttons/WithIcon"
import Loader from "@/components/Loader"

import ADVERTISER_STATS from "@/graphql/mutations/AdvertiserStats.gql"

import Login from "@/plugins/login"

library.add(fas)
library.add(far)
library.add(fab)

Vue.use(VueLazyload)
Vue.use(BootstrapVue)
Vue.use(PortalVue)
Vue.use(Login, { store: store })
Vue.use(Vue2TouchEvents)
Vue.use(ToggleButton)
Vue.use(VueI18n)

Vue.component("font-awesome-layers", FontAwesomeLayers)
Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.component("account-link", AccountLink)
Vue.component("btn-with-icon", BtnWithIcon)
Vue.component("loader", Loader)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA5NfbhYxd-QsrRoJtZkr_Ts7Gv9FF0gP8",
    libraries: "places"
  }
})

// Ready translated locale messages
const messages = {
  en: {
    message: {
      hello: "hello world"
    }
  },
  ja: {
    message: {
      hello: "こんにちは、世界"
    }
  },
  fr: {
    message: {
      accepted:
        "Votre réservation pour le {date} au {name} pour {guest} personne(s) a été validée. Nous vous recontacterons dans les plus bref délais par email.<br /><br />Si vous ne le voyez pas, pensez à regarder dans votre dossier spams/indésirables."
    }
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "fr", // set locale
  messages // set locale messages
})

import "./scss/app.scss"
import apolloProvider from "./vue-apollo"

moment.locale("fr")

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem("user_token", to.query.token)
    delete to.query.token
    router.push({ name: to.name, params: to.params, query: to.query })
    return
  }
  let parent = to.matched[0] || null
  if (parent && parent.meta.auth && !localStorage.user_token) {
    router.push("/public")
  } else {
    next()
  }
})

Vue.mixin({
  computed: {
    ...mapState({
      authenticated: state => state.loginModule.authenticated,
      layer: state => state.layer
    })
  }
})

Vue.directive("advertiser", {
  // Quand l'élément lié est inséré dans le DOM...
  inserted: function (el, binding, vnode) {
    // L'élément prend le focus
    let data = {
      type: "viewed",
      advertiserCampaignId: binding.value.campaignId,
      display: binding.value.view
    }
    vnode.context.$apollo
      .mutate({
        mutation: ADVERTISER_STATS,
        variables: data
      })
      .then(() => {})
      .catch(error => {})
  }
})

Vue.directive("advertiserClick", {
  // Quand l'élément lié est inséré dans le DOM...
  inserted: function (el, binding, vnode) {
    let data = {
      type: "clicked",
      advertiserCampaignId: binding.value.campaignId,
      display: binding.value.view
    }
    el.addEventListener("click", event => {
      vnode.context.$apollo
        .mutate({
          mutation: ADVERTISER_STATS,
          variables: data
        })
        .then(() => {})
        .catch(error => {})
    })
  }
})

Vue.filter("date", function (date, format = "DD/MM/YYYY") {
  if (!date) return ""
  return moment(date).format(format)
})

Vue.filter("timestamp", function (date, format = "DD/MM/YYYY") {
  if (!date) return ""
  return moment.unix(date).format(format)
})

new Vue({
  router,
  i18n,
  store,
  apolloProvider: apolloProvider,
  render: h => h(App)
}).$mount("#app")
