<template>
  <b-modal
    v-if="venue"
    id="dealModal"
    ref="dealModalRef"
    title="Nouveau Deal"
    modal-class="icon-modal"
    :hide-footer="loading"
    centered
    @hidden="onHidden"
  >
    <div slot="modal-header">
      <div class="icon-wrapper">
        <font-awesome-icon
          icon="thumbs-up"
          class="text-white"
          size="2x"
          fixed-width
        />
      </div>
    </div>
    <div v-if="loading || $apollo.queries.checkDeal.loading">
      <loader />
    </div>
    <div v-else-if="!checkDeal">
      Vous ne pouvez pas utiliser ce bon plan car vous avez deja effectué un Bon
      Plan il ya moins de 30 jours dans ce restaurant.
    </div>
    <div v-else-if="can">
      <div class="d-block text-center">
        <div class="body-title">Débloquez un bon plan</div>
        <p>
          Souhaitez vous débloquer ce bon plan ? Pensez à photographier votre
          addition pour la valider
        </p>
      </div>
    </div>
    <div v-else>
      <div class="d-block text-center">
        <div class="body-title">Vous avez déjà un Bon Plan</div>
        Vous avez déjà un bon plan débloqué. Si vous débloquez un bon plan
        supplémentaire, cela entrainera la suppression du premier bon plan
        débloqué.
      </div>
    </div>
    <div
      v-if="!loading && can"
      slot="modal-footer"
      class="w-100 d-flex justify-content-center align-items-end"
    >
      <b-button
        variant="outline-dark"
        class="rounded-pill text-uppercase mr-4"
        @click="close"
      >
        non
      </b-button>
      <b-button
        variant="primary"
        class="rounded-pill text-uppercase"
        :disabled="loading"
        @click="use(venue.activeDeals[0].id)"
      >
        oui
        <font-awesome-icon v-if="loading" icon="spinner" fixed-width spin />
      </b-button>
    </div>
    <div
      v-else-if="!loading"
      slot="modal-footer"
      class="w-100 d-flex justify-content-center align-items-end"
    >
      <b-button
        variant="outline-dark"
        class="rounded-pill text-uppercase mr-4"
        @click="close"
      >
        non
      </b-button>
      <b-button
        variant="primary"
        class="rounded-pill text-uppercase"
        :disabled="loading"
        @click="cancel"
      >
        oui
        <font-awesome-icon v-if="loading" icon="spinner" fixed-width spin />
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import ACTIVE_DEALS from "@/graphql/auth/activeDeals.gql"
import USER_VENUE_DEALS from "@/graphql/auth/userVenueDeals.gql"
import USE_DEAL from "@/graphql/mutations/UseDeal.gql"
import CANCEL_DEAL from "@/graphql/mutations/CancelDeal.gql"
import CHECK_DEAL from "@/graphql/auth/checkDeal.gql"
import Loader from "@/components/Loader"
import { first } from "@/utils"
export default {
  name: "DealModal",
  components: {
    Loader
  },
  props: {
    venue: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    loading: false
  }),
  apollo: {
    // Simple query that will update the 'hello' vue property
    activeDeals: {
      query: ACTIVE_DEALS,
      skip: true,
      client: "auth",
      // Additional options here
      update: data => data.me.activeDeals
    },
    pendingDeals: {
      query: USER_VENUE_DEALS,
      skip: true,
      client: "auth",
      varaibles: {
        status: ["pending"]
      }
    },
    checkDeal: {
      query: CHECK_DEAL,
      skip: true,
      client: "auth"
    }
  },
  computed: {
    can: function() {
      return !this.activeDeals || this.activeDeals.length < 1
    },
    activeDeal: function() {
      return first(this.activeDeals)
    }
  },
  watch: {
    venue: function() {
      if (this.venue && this.venue.id) {
        this.$nextTick(() => {
          this.$refs.dealModalRef.show()
          this.doCheckDeal().then(response => {
            if (this.checkDeal) {
              this.getActiveDeal()
            } else {
              this.$refs.dealModalRef.hide()
              this.$store.commit("noDeal", true)
            }
          })
        })
      }
    }
  },
  methods: {
    onHidden() {
      this.$nextTick(() => {
        this.$store.commit("dealVenue", null)
      })
    },
    doCheckDeal() {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$apollo.queries.checkDeal.skip = false
        this.$apollo.queries.checkDeal
          .refetch({
            venueDealId: this.venue.activeDeals[0].id
          })
          .then(response => {
            this.loading = false
            resolve()
          })
          .catch(error => {
            if (
              error.message ===
              "Network error: Response not successful: Received status code 401"
            ) {
              this.$login
                .login()
                .then(response => {
                  this.doCheckDeal().then(response => {
                    resolve()
                  })
                })
                .catch(error => {
                  this.close()
                  reject(error)
                })
            }
          })
      })
    },
    getActiveDeal() {
      this.loading = true
      return new Promise((resolve, reject) => {
        if (this.authenticated) {
          this.$apollo.queries.activeDeals.skip = false
          this.$apollo.queries.activeDeals.refetch().then(response => {
            this.loading = false
            resolve()
          })
        } else {
          this.loading = false
          resolve()
        }
      })
    },
    use(venueDealId) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.getActiveDeal().then(() => {
          this.loading = true
          if (this.can) {
            this.$apollo
              .mutate({
                mutation: USE_DEAL,
                client: "auth",
                variables: {
                  venueDealId
                }
              })
              .then(() => {
                this.venue.activeDeals = []
                this.close()
                resolve()
              })
              .catch(error => {
                this.loading = false
                if (error.message === "GraphQL error: LIMIT_DEAL_REACHED") {
                  this.$refs.dealModalRef.hide()
                  this.$store.commit("noDeal", true)
                }
              })
          }
        })
      })
    },
    cancel() {
      this.loading = true
      this.$apollo
        .mutate({
          mutation: CANCEL_DEAL,
          client: "auth",
          variables: {
            userVenueDealId: this.activeDeal.id
          }
        })
        .then(() => {
          this.use(this.venue.activeDeals[0].id)
        })
        .catch(() => {})
    },
    close() {
      this.$refs.dealModalRef.hide()
    }
  }
}
</script>
