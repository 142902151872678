<template>
  <btn-with-icon
    variant="primary"
    :icon="['fas', 'gift']"
    :only-icon="icon"
    v-bind="$attrs"
    class="rounded-pill py-1 text-uppercase"
    :href="href"
    @click.prevent
  >
    <span v-if="!icon">Bon cadeau</span>
  </btn-with-icon>
</template>
<script>
import BtnWithIcon from "@/components/buttons/WithIcon";
export default {
  components: {
    BtnWithIcon,
  },
  props: {
    venue: {
      type: Object,
      required: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    href: {
      type: String,
      required: true,
    },
  },
};
</script>
