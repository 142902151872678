<template>
  <layout-header>
    <template v-slot:nav>
      <portal-target
        name="header-top"
        class="d-flex w-100 flex-row align-items-center"
      >
        <account-link />
        <b-nav-form v-if="ready" class="d-flex w-100" @submit.prevent>
          <div class="flex-fill mx-3">
            <b-btn
              variant="white"
              block
              size="sm"
              class="d-flex justify-content-center align-items-center rounded-pill position-relative text-uppercase"
              @click.prevent="
                selectLocation = !selectLocation
                searching = false
              "
            >
              <span class="font-weight-bold">{{ city }}</span>
              <font-awesome-icon
                v-if="!selectLocation"
                icon="arrow-circle-down"
                class="position-absolute"
                style="right: 20px"
              />
              <font-awesome-icon
                v-else
                icon="arrow-circle-up"
                class="position-absolute"
                style="right: 20px"
              />
            </b-btn>
          </div>
          <b-button
            v-if="!searching"
            :href="`https://grosmiam.fr?from=webapp&lat=${coordinate.lat}&lng=${coordinate.lng}&place=${city}`"
            target="_blank"
            size="sm"
            class="flat"
            type="button"
          >
            <img src="../assets/img/grosmiam.png" height="30" />
          </b-button>
          <b-button
            v-else
            size="sm"
            class="flat"
            type="button"
            @click.prevent="searching = !searching"
          >
            <font-awesome-icon icon="times" fixed-width size="lg" />
          </b-button>
        </b-nav-form>
        <fit-height-loader v-else size="1x" />
      </portal-target>
    </template>
    <template v-slot:after-nav>
      <portal-target
        v-show="!selectLocation && !searching"
        name="header-bottom"
      />
      <!-- <search :searching="searching" @cancel="searching=false"></search> -->
    </template>
    <template v-slot:bottom>
      <div
        v-if="selectLocation || searching"
        class="position-absolute w-100 bg-white"
        style="z-index: 1000"
        :style="'height:' + height"
      >
        <mdj-city-selector
          v-if="selectLocation"
          :search="searching"
          :change-city="!searching"
          :help-text="true"
          @input="onInput"
          @radius="onRadius"
        />

        <search
          v-else
          class="mt-2 d-flex flex-fill"
          :search-result="true"
          @cancel="searching = false"
        />
      </div>
    </template>
  </layout-header>
</template>
<script>
import { mapState } from "vuex"
import Search from "@/components/Search"
import LayoutHeader from "@/components/LayoutHeader.vue"
import FitHeightLoader from "@/components/FitHeightLoader"
import location from "@/mixins/location"
import MDJCitySelector from "@/components/CitySelector"
export default {
  name: "Header",
  components: {
    FitHeightLoader,
    LayoutHeader,
    Search,
    "mdj-city-selector": MDJCitySelector
  },
  mixins: [location],
  data() {
    return {
      searching: false,
      errorLocation: null,
      currentLocation: { lat: 0, lng: 0 },
      selectLocation: false,
      ready: false
    }
  },
  computed: {
    ...mapState({
      coordinate: state => state.coordinate,
      radius: state => state.radius,
      place: state => state.city
    }),
    height: function () {
      return (
        window.outerHeight -
        document.querySelector("header nav").offsetHeight +
        "px"
      )
    }
  },
  watch: {
    $route: function () {
      this.selectLocation = false
      this.searching = false
    }
  },
  created() {
    this.$root.$on("search", () => {
      this.searching = true
    })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
form.form-inline {
  width: 100%;
}
</style>
