<template>
  <b-modal
    v-if="show"
    id="noDealModalRef"
    ref="noDealModalRef"
    title="Plus de deal"
    modal-class="icon-modal"
    centered
    ok-only
    @hidden="onHidden"
  >
    <div slot="modal-header">
      <div class="icon-wrapper">
        <font-awesome-icon
          icon="thumbs-up"
          class="text-white"
          size="2x"
          fixed-width
        />
      </div>
    </div>
    <div>
      Vous ne pouvez pas utiliser ce bon plan car vous avez deja effectué un Bon
      Plan il ya moins de 30 jours dans ce restaurant.
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "NoDealModal",
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    loading: false
  }),
  watch: {
    show: function() {
      if (this.show) {
        this.$nextTick(() => {
          this.$refs.noDealModalRef.show()
        })
      }
    }
  },
  methods: {
    onHidden() {
      this.$nextTick(() => {
        this.$store.commit("noDeal", false)
      })
    },
    close() {
      this.$refs.noDealModalRef.hide()
    }
  }
}
</script>
