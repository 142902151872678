<template>
  <div class="h-100 city-selector">
    <b-container class="p-2 d-flex flex-column">
      <b-input-group class="form-search-control">
        <gmap-autocomplete
          id="autocomplete"
          ref="autocomplete"
          :value="localCity"
          autofocus
          placeholder="Indiquez une ville"
          class="form-control"
          required
          :sensor="true"
          @place_changed="locationItemSelected"
          @focus="canChangeCity = true"
        />
        <b-input-group-append class="">
          <b-button
            size="sm"
            variant="outline-secondary"
            class="border-left-0"
            @click="resetCity"
          >
            <font-awesome-icon icon="times" fixed-width />
          </b-button>
        </b-input-group-append>
      </b-input-group>

      <div v-if="canChangeCity && showRadius" class="mt-2 row no-gutters">
        <div class="col-3">+{{ userRadius }} km</div>
        <div class="col-9">
          <vue-slider
            v-model="userRadius"
            :tooltip="false"
            formatter="{value} km"
            :min="1"
            @drag-start="initRadius = false"
            @input="debouncedCommitRadius()"
          />
        </div>
      </div>
    </b-container>

    <div v-if="canChangeCity" class="pac-item bg-white text-center">
      <b-container>
        <div style="margin-right: 7px" class="d-inline-block">
          <font-awesome-icon icon="location-arrow" fixed-width />
        </div>
        <b-link class="pac-item-query pac-matched" @click.prevent="geoloc">
          Autour de ma position
        </b-link>
      </b-container>
    </div>
    <b-container v-if="helpText">
      <small>
        Choisissez votre "ville" ou votre "adresse, ville" ou votre "code
        postal" ci-dessus et le rayon de recherche ou cliquez
        <b-link @click="validate">
          <u><b>ici</b></u>
        </b-link>
        pour valider la sélection actuelle
      </small>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex"
import _debounce from "lodash/debounce"
import vueSlider from "vue-slider-component"
export default {
  components: {
    vueSlider
  },
  props: {
    changeCity: {
      type: Boolean,
      required: false,
      default: true
    },
    showRadius: {
      type: Boolean,
      required: false,
      default: true
    },
    updateStore: {
      type: Boolean,
      required: false,
      default: true
    },
    helpText: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      localCity: null,
      userRadius: this.radius,
      canChangeCity: this.changeCity,
      localCoordinate: {
        lat: null,
        lng: null
      },
      initRadius: true
    }
  },
  computed: {
    ...mapState({
      coordinate: state => state.coordinate,
      radius: state => state.radius,
      city: state => state.city
    })
  },
  created() {
    this.debouncedCommitRadius = _debounce(this.commitRadius, 2000)
    this.userRadius = this.radius
    // this.localCity = this.city
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.querySelector("#autocomplete").focus()
    })
  },
  methods: {
    geoloc() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.$emit(
            "input",
            position.coords.latitude,
            position.coords.longitude
          )
        })
      }
    },
    locationItemSelected(addressData) {
      this.$emit(
        "input",
        addressData.geometry.location.lat(),
        addressData.geometry.location.lng()
      )
    },
    validate() {
      this.$emit("input", this.coordinate.lat, this.coordinate.lng)
    },
    commitRadius() {
      if (this.initRadius) {
        this.initRadius = false
      } else {
        this.$emit("radius", this.userRadius)
      }
    },
    resetCity() {
      this.localCity = null
      this.$refs.autocomplete.$el.value = this.localCity
      this.$nextTick(() => {
        this.$el.querySelector("#autocomplete").focus()
      })
      this.$emit("clear")
    }
  }
}
</script>
