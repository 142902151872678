export default (search, text) => {
  if (!search || search === "") {
    return text
  }
  const preparedSearch = search
    .normalize("NFD")
    .replace(/[\u0300-\u036f+]/g, "")
  const regExpName = new RegExp(preparedSearch, "ig")
  const name = text.normalize("NFD").replace(/[\u0300-\u036f+]/g, "")
  const m = Array.from(name.matchAll(regExpName))
  let start = ""
  let end = ""
  let middle = ""
  let final = text
  for (let i = m.length - 1; i >= 0; i--) {
    start = final.substring(0, m[i].index)
    middle = final.substring(m[i].index, m[i].index + preparedSearch.length)
    end = final.substring(m[i].index + preparedSearch.length)
    final = start + "<span class='highlight'>" + middle + "</span>" + end
  }

  /*
  if (m !== null) {
    console.log(m, m[0], text.substring(0, m.index))
    return text.replace(m[0], "<span class='highlight'>$&</span>")
  }
  */
  return final
}
