<template>
  <div>
    <div class="">
      <b-form-group
        :label="label"
        :invalid-feedback="invalidFeedback"
        class="w-100"
      >
        <div class="d-flex">
          <b-form-input
            type="text"
            class="intl-phone w-full"
            :required="required"
            :state="computedState"
            :invalid-feedback="invalidFeedback"
            :placeholder="placeholder"
            @blur="twilio"
          />
          <b-button
            variant="primary"
            @click="twilio"
            type="button"
            class="ml-3"
          >
            <font-awesome-icon icon="sync-alt" size="sm" />
          </b-button>
        </div>
        <b-form-valid-feedback v-if="valid" :state="valid">
          {{
            `${translations["valid.phone"] || "Votre téléphone a été validé"}`
          }}
        </b-form-valid-feedback>
        <b-form-invalid-feedback v-else :state="computedState">
          {{
            `${
              translations["errors.phone"] ||
              "Votre numéro de téléphone est incorrect"
            }`
          }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <b-modal
      id="verify"
      ref="verifyModalRef"
      hide-footer
      @hide="resetModal"
      @ok="handleOk"
    >
      <h6>
        Merci de saisir le code que vous avez reçu par SMS pour valider votre
        numéro de téléphone
      </h6>
      <b-form-group label="Code" label-for="code">
        <b-form-input
          id="code"
          v-model="code"
          type="text"
          required
          autofocus
          placeholder="Code"
          :state="error && error['code'] ? false : null"
          pattern="[0-9+]"
        />
        <b-form-invalid-feedback v-if="error.code" :state="false">
          {{ error.code }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-btn :disabled="loading || disable" @click.stop.prevent="doVerify">
        Envoyer<b-spinner
          v-if="loading"
          small
          label="Loading..."
          class="ml-2"
        ></b-spinner>
      </b-btn>
    </b-modal>
  </div>
</template>

<script>
import TWILIO_START from "@/graphql/TwilioStart.gql"
import TWILIO_VERIFY from "@/graphql/TwilioVerify.gql"
import { first } from "@/utils"
import intlTelInput from "intl-tel-input"

export default {
  props: {
    label: {
      type: String,
      default: "Téléphone"
    },
    translations: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    },
    invalidFeedback: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      error: {},
      loading: false,
      code: null,
      phone: null,
      valid: null,
      nameState: null,
      initialPhone: null,
      unclosable: false,
      intl: null
    }
  },
  computed: {
    computedState: function () {
      return this.valid === null ? this.state : this.valid
    },
    disable: function () {
      return null === this.code || this.code === ""
    }
  },
  watch: {
    phone: function () {
      this.error = {}
      this.valid = null
    },
    loading: function () {
      this.$emit("loading", this.loading)
    }
  },

  created() {
    this.phone = this.value
    this.initialPhone = this.phone
  },
  mounted() {
    var input = document.querySelector(".intl-phone")
    this.intl = intlTelInput(input, {
      customPlaceholder: () => {
        return ""
      },
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
      initialCountry: "FR"
    })
    setTimeout(() => {
      this.intl.setNumber(this.value)
    }, 1)
  },
  methods: {
    setCountry(country) {
      this.valid = null
      this.phone = null
      this.intl.setCountry(country)
    },
    twilio() {
      const phone = this.intl.getNumber()
      this.valid = null
      if (!this.intl.isValidNumber()) {
        this.valid = false
        return false
      }
      if (null === phone || phone === "") {
        return
      }
      this.phone = phone
      this.code = ""
      this.error = {}
      this.loading = true
      if (this.valid) {
        this.$emit("input", phone)
      } else {
        this.error = {}
        this.loading = true
        let data = {
          phone: phone
        }
        this.$apollo
          .query({
            query: TWILIO_START,
            variables: data
          })
          .then(response => {
            this.loading = false
            this.unclosable = false
            this.$refs.verifyModalRef.show()
          })
          .catch(error => {
            this.loading = false
            this.valid = false
            this.phone = null
            this.$refs.verifyModalRef.hide()
            if (error.graphQLErrors) {
              let validation = first(error.graphQLErrors).message
              if (validation === "validation") {
                this.error["code"] = this.translations["errors.phone"]
              }
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    doVerify() {
      this.error = {}
      this.loading = true
      this.unclosable = true
      let data = {
        phone: this.phone,
        code: this.code
      }
      this.$apollo
        .query({
          query: TWILIO_VERIFY,
          variables: data
        })
        .then(response => {
          if (response.data && response.data.twilioVerify) {
            this.valid = true
            this.$emit("input", this.phone)
            this.$nextTick(() => {
              this.$refs.verifyModalRef.hide()
            })
          } else {
            this.error["code"] = "Code invalide"
          }
        })
        .catch(error => {
          this.loading = false
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].message === "INVALID_CODE"
          ) {
            this.error["code"] = "Code invalide"
          } else {
            this.error["code"] = "Une erreur est survenue"
          }
        })
        .finally(response => {
          this.loading = false
        })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      // Trigger submit handler
      this.doVerify()
    },
    resetModal(bvModalEvt) {
      if (this.unclosable && !this.valid) {
        bvModalEvt.preventDefault()
      } else {
        if (!this.valid) {
          this.phone = this.initialPhone
          this.intl.setNumber(this.initialPhone)
          this.$emit("input", "")
          this.valid = false
        }
      }
    }
  }
}
</script>
