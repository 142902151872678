<template>
  <b-modal
    v-if="venue"
    id="shareModal"
    ref="shareModalRef"
    title="Partage"
    :hide-footer="true"
    modal-class="icon-modal"
    centered
    @hidden="onHidden"
  >
    <div slot="modal-header">
      <div class="icon-wrapper">
        <font-awesome-icon
          :icon="['far', 'paper-plane']"
          class="text-white"
          size="2x"
          fixed-width
        />
      </div>
    </div>
    <b-input-group>
      <b-form-input id="inputCopied" v-model="venue.tinyUrl" />
      <b-input-group-append>
        <b-btn
          variant="outline-secondary"
          :disabled="copied"
          @click.prevent="copy"
        >
          <span v-if="copied" class="text-success">
            <font-awesome-icon icon="check" />Copié
          </span>
          <span v-else>Copier</span>
        </b-btn>
      </b-input-group-append>
    </b-input-group>
    <b-btn variant="facebook" block class="mt-3" @click.prevent="share">
      FACEBOOK
    </b-btn>
  </b-modal>
</template>
<script>
export default {
  name: "ShareModal",
  props: {
    venue: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    shareUrl: function () {
      return (
        "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(this.venue.tiny_url) +
        "&amp;src=sdkpreparse"
      );
    },
  },
  watch: {
    venue: function () {
      if (this.venue && this.venue.id) {
        this.$nextTick(() => {
          this.$refs.shareModalRef.show();
        });
      }
    },
  },
  methods: {
    share() {
      window.FB.ui(
        {
          method: "share",
          href: this.venue.tinyUrl,
        },
        function (response) {}
      );
    },
    copy() {
      this.copied = true;
      document.getElementById("inputCopied").select();
      document.execCommand("copy");
      this.$refs.shareModalRef.show();
    },
    onHidden() {
      this.$nextTick(() => {
        this.copied = false;
        this.$store.commit("shareVenue", null);
      });
    },
  },
};
</script>
