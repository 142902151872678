export default {
  props: {
    auto: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.auto) {
      this.doAction()
    }
  }
}
