<template>
  <div>
    <b-alert variant="danger" :show="error">{{ errorMessage }}</b-alert>
    <div v-if="mode == null" class="d-block text-center">
      <h4 class="text-center">Créez votre compte ou reconnectez-vous pour :</h4>
      <ul class="text-left" style="list-style: disc">
        <li>Mémoriser vos établissements à tester.</li>
        <li>
          Enregistrer vos favoris et recevoir leurs Menus Du Jour et leurs
          actualités.
        </li>
        <li>Réserver votre table en ligne ou par téléphone.</li>
      </ul>
      <btn-with-icon
        variant="outline-primary"
        :icon="['fas', 'phone']"
        block
        :only-icon="false"
        :loading="connecting"
        @click="mode = 'twilio'"
      >
        Votre téléphone
      </btn-with-icon>
      <btn-with-icon
        variant="outline-success"
        :icon="['fas', 'at']"
        block
        :only-icon="false"
        :loading="connecting"
        @click="mode = 'email'"
      >
        Votre email
      </btn-with-icon>
    </div>
    <div v-else-if="mode == 'email'" class="d-block text-center">
      <h4 class="text-center">Entrez votre adresse email</h4>
      <b-form @submit.prevent="emailConnection(email, password)">
        <b-form-group>
          <b-form-input
            id="mdj-email"
            v-model="email"
            type="email"
            placeholder="monadresse@email.com"
            name="email"
            autocomplete="mdj-login-email"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
            id="mdj-password"
            v-model="password"
            type="password"
            placeholder="Mot de passe"
            name="password"
            autocomplete="mdj-login-password"
          />
        </b-form-group>
        <small class="d-block">
          <b-link @click.prevent="mode = 'resetPassword'">
            Vous avez oublié votre mot de passe ?
          </b-link>
        </small>
        <btn-with-icon
          type="submit"
          :icon="['fas', 'sign-in-alt']"
          variant="primary"
          block
          :only-icon="false"
          :loading="connecting"
        >
          <template v-if="registration"> Inscrivez-vous </template>
          <template v-else> Connectez-vous </template>
        </btn-with-icon>
        <small>
          <b-link @click.prevent="mode = null">
            Retour aux autres méthodes
          </b-link>
        </small>
      </b-form>
    </div>
    <div v-else-if="mode == 'resetPassword'" class="d-block text-center">
      <h4 class="text-center">Mot de passe oublié</h4>
      <b-form v-if="!reseted" @submit.prevent="resetPassword(resetEmail)">
        <b-form-group>
          <b-form-input
            id="mdj-reset-email"
            v-model="resetEmail"
            type="email"
            placeholder="monadresse@email.com"
            name="reset-email"
            autocomplete="mdj-reset-email"
          />
        </b-form-group>

        <btn-with-icon
          v-if="!reseted"
          type="submit"
          :icon="['fas', 'arrow-right']"
          variant="success"
          block
          :only-icon="false"
          :loading="connecting"
        >
          Recevoir les instructions
        </btn-with-icon>
        <small>
          <b-link @click.prevent="mode = 'email'"> Annuler </b-link>
        </small>
      </b-form>
      <b-alert v-else show variant="success">
        Un email vous a été envoyé
        <b-link class="d-block" @click.prevent="mode = 'email'">
          Retour
        </b-link>
      </b-alert>
    </div>
    <div v-else-if="mode == 'twilio'" class="d-block text-center">
      <h4 class="text-center">Entrez votre numéro de téléphone</h4>
      <b-form @submit.prevent="twilioSubmit()">
        <b-form-group>
          <b-input-group prepend="+33">
            <b-form-input
              v-model="phone"
              type="tel"
              name="phone"
              placeholder="Votre numéro de téléphone"
              autocomplete="current-phone"
              required
              :disabled="twilioCheck || connecting"
              :state="
                errorObject ? !(errorObject && errorObject['phone']) : null
              "
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="errorObject && errorObject.phone"
            :state="false"
          >
            {{ errorObject.phone }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="twilioCheck">
          <b-form-input
            v-model="code"
            placeholder="Votre code"
            type="text"
            name="code"
            required
            autocomplete="current-phone"
            :disabled="connecting"
            :state="errorObject ? !(errorObject && errorObject['code']) : null"
          />
          <b-form-invalid-feedback
            v-if="errorObject && errorObject.code"
            :state="false"
          >
            {{ errorObject.code }}
          </b-form-invalid-feedback>
        </b-form-group>
        <btn-with-icon
          type="submit"
          :icon="['fas', 'sign-in-alt']"
          :only-icon="false"
          variant="success"
          block
          :loading="connecting"
        >
          <template v-if="twilioCheck"> Connectez-vous </template>
          <template v-else> Recevoir mon code de connexion </template>
        </btn-with-icon>
        <b-link
          v-if="twilioCheck"
          class="d-block"
          @click="
            code = null
            twilioCheck = false
            errorObject = null
          "
        >
          Recevoir un nouveau code
        </b-link>
        <small>
          <b-link @click.prevent="mode = null">
            Retour aux autres méthodes
          </b-link>
        </small>
      </b-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { mapState } from "vuex"
import EMAIL_LOGIN from "@/graphql/mutations/EmailLogin.gql"
import RESET_PASSWORD from "@/graphql/mutations/ResetPassword.gql"
import SOCIAL_LOGIN from "@/graphql/mutations/SocialLogin.gql"
import TWILIO_START from "@/graphql/TwilioStart.gql"
import TWILIO_LOGIN from "@/graphql/mutations/TwilioLogin.gql"
export default {
  data() {
    return {
      loading: false,
      password: null,
      email: null,
      error: null,
      mode: null,
      processing: false,
      isSuccess: false,
      phone: null,
      code: null,
      twilioCheck: false,
      errorMessage: null,
      errorObject: null,
      resetEmail: null,
      reseted: false,
      registration: false
    }
  },
  apollo: {
    twilioStart: {
      query: TWILIO_START,
      skip: true,
      manual: true,
      variables: {
        phone: ""
      },
      result({ data, loading }) {
        if (loading) {
          return
        }
        if (!data) {
          return
        }
        if (data.twilioStart && data.twilioStart.status === "pending") {
          this.resetError()
          this.twilioCheck = true
        }
      },
      error(errors) {
        this.setError(errors)
        this.twilioCheck = false
      }
    }
  },
  computed: {
    connecting: function () {
      return this.$apollo.loading || this.processing
    },
    ...mapState({
      title: state => state.loginModule.title,
      titleRegistration: state => state.loginModule.titleRegistration,
      titleConnection: state => state.loginModule.titleConnection
    })
  },
  methods: {
    success(token, recentlyCreated) {
      this.processing = false
      this.isSuccess = true
      this.$login.ready(token, recentlyCreated).then(response => {
        this.$emit("login", response.token, response.recentlyCreated)
      })
    },
    fail(message = null) {
      this.processing = false
      this.error = true
      this.errorMessage = message || "Une erreur est survenue"
    },
    socialLogin(provider, token) {
      this.$apollo
        .mutate({
          mutation: SOCIAL_LOGIN,
          variables: {
            provider: provider,
            token: token
          }
        })
        .then(response => {
          if (response.data.socialLogIn) {
            this.success(
              response.data.socialLogIn.token,
              response.data.socialLogIn.recentlyCreated
            )
          }
        })
        .catch(error => {
          this.fail()
        })
    },
    emailConnection(email, password) {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: EMAIL_LOGIN,
          variables: {
            email,
            password
          }
        })
        .then(response => {
          if (response.data.emailLogIn) {
            this.success(
              response.data.emailLogIn.token,
              response.data.emailLogIn.recentlyCreated
            )
          }
        })
        .catch(error => {
          error = _.first(error.graphQLErrors)
          this.fail(error ? error.message : null)
        })
    },
    twilioSubmit() {
      this.resetError()
      this.processing = false
      if (this.twilioCheck) {
        this.twilioLogin()
      } else {
        let phone = this.phone
        this.$apollo.queries.twilioStart.setVariables({
          phone
        })
        this.$apollo.queries.twilioStart.skip = false
        this.$apollo.queries.twilioStart.refetch()
      }
    },
    twilioLogin() {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: TWILIO_LOGIN,
          variables: {
            phone: this.phone,
            code: this.code
          }
        })
        .then(response => {
          this.processing = false
          if (response.data.twilioLogin) {
            this.success(
              response.data.twilioLogin.token,
              response.data.twilioLogin.recentlyCreated
            )
          } else {
            this.fail(
              "Une erreur est survenue lors de la vérification du code."
            )
          }
        })
        .catch(error => {
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].message === "INVALID_CODE"
          ) {
            this.errorObject = {
              code: "Code invalide"
            }
          } else {
            this.fail()
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    resetError() {
      this.error = false
      this.$set(this, "errorObject", null)
    },
    setError(error) {
      if (error.graphQLErrors) {
        let validation = _.first(error.graphQLErrors).extensions.validation
        if (validation) {
          this.errorObject = _.chain(validation)
            .mapValues(e => _.first(e))
            .value()
        }
      }
    },
    resetPassword(email) {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: RESET_PASSWORD,
          variables: {
            email
          }
        })
        .then(response => {
          let data = response.data
          if (data && data.resetPassword) {
            this.reseted = true
          }
        })
        .catch(error => {})
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>
