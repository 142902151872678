<template>
  <b-btn size="sm" class="flat" :disabled="loading" @click.prevent="bookmark">
    <font-awesome-icon
      :icon="icon"
      fixed-width
      :spin="loading"
      size="lg"
      class="text-primary"
    />
  </b-btn>
</template>
<script>
import autoAction from "@/mixins/autoAction"
export default {
  mixins: [autoAction],
  props: {
    venue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    variant: function() {
      return this.venue.userBookmark ? "primary" : "white"
    },
    icon: function() {
      return this.loading
        ? ["fas", "spinner"]
        : this.venue.userBookmark
        ? ["fas", "heart"]
        : ["far", "heart"]
    }
  },
  methods: {
    doAction() {
      this.bookmark()
    },
    bookmark() {
      this.$store.commit("bookmarkVenue", this.venue)
    }
  }
}
</script>
