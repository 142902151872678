<template>
  <b-btn variant="primary" size="sm" @click.prevent="share">
    <font-awesome-icon :icon="['far', 'paper-plane']" fixed-width size="lg" />
  </b-btn>
</template>
<script>
import autoAction from "@/mixins/autoAction";
export default {
  mixins: [autoAction],
  props: {
    venue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    doAction() {
      this.share();
    },
    share() {
      this.$store.commit("shareVenue", this.venue);
    },
  },
};
</script>
