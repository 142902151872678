import Vue from "vue"
import VueApollo from "vue-apollo"
// import ApolloClient from "apollo-boost"

import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { onError } from "apollo-link-error"
import { withClientState } from "apollo-link-state"
import { ApolloLink, Observable } from "apollo-link"
import { createUploadLink } from "apollo-upload-client"

const graphql =
  process.env.VUE_APP_GRAPHQL_HTTP || "https://api.du-jour.fr/graphql"

const cache = new InMemoryCache({
  cacheRedirects: {}
})

const request = async operation => {
  const token = localStorage.user_token
  if (token) {
    operation.setContext({
      headers: {
        authorization: "Bearer " + token,
        accept: "application/json, text/plain, */*"
      }
    })
  } else {
    operation.setContext({
      headers: {
        accept: "application/json, text/plain, */*"
      }
    })
  }
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

function client(uri) {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          )
        if (networkError) console.log(`[Network error]: ${networkError}`)
        if (networkError && networkError.statusCode === 401) {
          localStorage.removeItem("user_token")
        }
      }),
      requestLink,
      withClientState({
        defaults: {
          isConnected: true
        },
        resolvers: {
          Mutation: {
            updateNetworkStatus: (_, { isConnected }, { cache }) => {
              cache.writeData({ data: { isConnected } })
              return null
            }
          }
        },
        cache
      }),
      createUploadLink({
        uri: uri
      })
    ]),
    cache: new InMemoryCache()
  })
}

const apolloClient = client(graphql)
const authApolloClient = client(graphql + "/auth")

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  clients: {
    auth: authApolloClient
  }
})

Vue.use(VueApollo)

export default apolloProvider
