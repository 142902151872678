import LoginModalComponent from "./components/LoginModal";
import LoginPageComponent from "./components/LoginPage";
import LoginClass from "./Login";

export default class Login {}

const LoginModule = {
  namespaced: true,
  state() {
    return {
      title: "Connectez-vous pour avoir accès aux Bons Plans",
      titleRegistration: "Inscrivez-vous pour avoir accès aux Bons Plans",
      titleConnection: "Connectez-vous pour avoir accès aux Bons Plans",
      authenticated: false,
      authenticatedUser: null,
    };
  },
  mutations: {
    title(state, payload) {
      state.title = payload;
      state.titleRegistration = payload;
      state.titleConnection = payload;
    },
    titleRegistration(state, payload) {
      state.titleRegistration = payload;
    },
    titleConnection(state, payload) {
      state.titleConnection = payload;
    },
    authenticated(state, value) {
      state.authenticated = value;
    },
    authenticatedUser(state, value) {
      state.authenticatedUser = value;
    },
  },
  // mutations, actions, getters...
};

Login.install = function (Vue, options) {
  if (options.store) {
    let store = options.store;
    store.registerModule("loginModule", LoginModule);
  } else {
    throw Exception("state options required");
  }
  const _login = new LoginClass();

  Vue.mixin({
    mounted() {
      _login.init(this.$root);
    },
  });
  _login.install();
  Vue.component("LoginModal", LoginModalComponent);
  Vue.component("LoginPage", LoginPageComponent);

  Vue.prototype.$login = _login;
};
