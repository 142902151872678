export default class Login {
  constructor() {
    this._tokenName = "user_token"
  }

  init(app) {
    this.app = app
  }

  install() {
    this.facebook()
  }

  facebook() {
    window.fbAsyncInit = function() {
      FB.init({
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v12.0"
      })
    }
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/fr_FR/sdk.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
  }

  login(title = null) {
    return new Promise((resolve, reject) => {
      if (title) {
        this.app.$store.commit("loginModule/title", title)
      } else {
        this.app.$store.commit(
          "loginModule/titleRegistration",
          "Inscrivez-vous pour avoir accès aux Bons Plans"
        )
        this.app.$store.commit(
          "loginModule/titleConnection",
          "Connectez-vous pour avoir accès aux Bons Plans"
        )
      }
      if (!localStorage.user_token) {
        this.app.$store.commit("loginModule/authenticated", false)
      }
      if (this.app.$store.state.loginModule.authenticated) {
        resolve()
      } else {
        this.app.$emit("login")
        this.app.$once("LoginSuccessful", response => {
          resolve(response)
        })
        this.app.$once("LoginFail", error => {
          this.app.$store.commit("loginModule/authenticated", false)
          reject(error)
        })
        this.app.$once("LoginCancel", error => {
          reject(error)
        })
      }
    })
  }
  ready(token, recentlyCreated) {
    return new Promise((resolve, reject) => {
      localStorage.setItem(this._tokenName, token)
      this.app.$store.commit("loginModule/authenticated", true)
      resolve({ token, recentlyCreated })
    })
  }
  logout() {
    return new Promise((resolve, reject) => {
      this.app.$store.commit("loginModule/authenticated", false)
      localStorage.removeItem(this._tokenName)
      Object.values(this.app.$apollo.provider.clients).forEach(client =>
        client.cache.reset()
      )
      resolve()
    })
  }
}
