import Vue from "vue"
import Router from "vue-router"
const List = () => import("@/views/List.vue")
const Home = () => import("@/views/Home.vue")
const Layer = () => import("@/views/Layer.vue")
const Welcome = () => import("@/views/Welcome.vue")
const Geoloc = () => import("@/views/Geoloc.vue")
const City = () => import("@/views/City.vue")
const Login = () => import("@/views/Login.vue")
const LayoutApp = () => import("@/views/Layouts/App.vue")
const Account = () => import("@/views/Account/Index.vue")
const Settings = () => import("@/views/Account/Settings.vue")
const Email = () => import("@/views/Account/Email.vue")
const Phone = () => import("@/views/Account/Phone.vue")
const Iban = () => import("@/views/Account/Iban.vue")
const Credits = () => import("@/views/Account/Credits.vue")
const UserVenueDeals = () => import("@/views/Account/UserVenueDeals.vue")
const Booking = () => import("@/views/Account/Booking.vue")
const ConfirmBooking = () => import("@/views/Account/ConfirmBooking.vue")
const Checkins = () => import("@/views/Account/Checkins.vue")
const Profile = () => import("@/views/Account/Profile.vue")
const Venue = () => import("@/views/Venue.vue")
const Map = () => import("@/views/Map.vue")
const Filter = () => import("@/views/Filter.vue")
const Feed = () => import("@/views/Feed.vue")
const Discover = () => import("@/views/Discover.vue")
const Notifications = () => import("@/views/Account/Notifications.vue")
const Bookmarks = () => import("@/views/Bookmarks.vue")
const DiscoverLike = () => import("@/views/DiscoverLike.vue")
const SearchMenu = () => import("@/views/SearchMenu")
const SearchMenu2 = () => import("@/views/SearchMenu2")
const Campaign = () => import("@/views/Campaign")

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/public",
      name: "home",
      component: Home
    },
    {
      path: "/layer",
      name: "layer",
      component: Layer
    },
    {
      path: "/public/login",
      name: "public.login",
      component: Login
    },
    {
      path: "/welcome",
      name: "welcome",
      component: Welcome
    },
    {
      path: "/geoloc",
      name: "geoloc",
      component: Geoloc
    },
    {
      path: "/city",
      name: "city",
      component: City
    },
    {
      path: "/",
      component: LayoutApp,
      children: [
        {
          path: "",
          name: "list",
          component: List,
          children: [
            {
              path: "venue/:id/:action?",
              component: Venue,
              name: "venue"
            }
          ]
        },
        {
          path: "/bookmarks",
          name: "bookmarks",
          component: Bookmarks,
          children: [
            {
              path: "venue/:id",
              component: Venue
            }
          ]
        },
        {
          path: "/map",
          name: "map",
          component: Map,
          children: [
            {
              path: "venue/:id",
              component: Venue
            }
          ]
        },
        {
          path: "/discover",
          name: "discover",
          component: Discover,
          children: [
            {
              path: "venue/:id",
              component: Venue
            }
          ]
        },
        {
          path: "/filters",
          name: "filter",
          component: Filter,
          children: [
            {
              path: "venue/:id",
              component: Venue
            }
          ]
        },
        {
          path: "/account",
          name: "account",
          component: Account,
          children: [
            {
              path: "credits",
              name: "credits",
              component: Credits,
              children: [
                {
                  path: "venue/:id",
                  name: "credit-venue",
                  component: Venue
                }
              ]
            },
            {
              path: "user-venue-deals",
              name: "user-venue-deals",
              component: UserVenueDeals,
              children: [
                {
                  path: "venue/:id",
                  name: "user-venue-deals-venue",
                  component: Venue
                }
              ]
            },
            {
              path: "booking",
              name: "booking",
              component: Booking,
              children: [
                {
                  path: "venue/:id",
                  name: "booking-venue",
                  component: Venue
                },
                {
                  path: ":id",
                  name: "booking-reservation",
                  component: ConfirmBooking
                }
              ]
            },
            {
              path: "bookmarks",
              name: "account-bookmarks",
              component: Bookmarks,
              meta: {
                fromAccount: true
              },
              children: [
                {
                  path: "venue/:id",
                  name: "account-bookmark-venue",
                  component: Venue
                }
              ]
            },
            {
              path: "to-try",
              name: "account-to-try",
              component: DiscoverLike,
              meta: {
                fromAccount: true
              },
              children: [
                {
                  path: "venue/:id",
                  name: "account-to-try-venue",
                  component: Venue
                }
              ]
            },
            {
              path: "credits-captured",
              name: "credits-captured",
              component: Credits,
              meta: {
                status: ["captured"]
              },
              children: [
                {
                  path: "venue/:id",
                  name: "credit-captured-venue",
                  component: Venue
                }
              ]
            },
            {
              path: "credits-authorized",
              name: "credits-authorized",
              component: Credits,
              meta: {
                status: ["authorized"]
              },
              children: [
                {
                  path: "venue/:id",
                  name: "credit-authorised-venue",
                  component: Venue
                }
              ]
            },
            {
              path: "checkins",
              name: "checkins",
              component: Checkins,
              children: [
                {
                  path: "venue/:id",
                  name: "checkin-venue",
                  component: Venue
                }
              ]
            },
            {
              path: "notifications",
              name: "notifications",
              component: Notifications
            },
            {
              path: "profile",
              name: "profile",
              component: Profile,
              meta: {
                disableScrolling: true
              }
            },
            {
              path: "settings",
              name: "settings",
              component: Settings,
              meta: {
                disableScrolling: true
              },
              children: [
                {
                  path: "email",
                  name: "email",
                  component: Email,
                  meta: {
                    disableScrolling: true
                  }
                },
                {
                  path: "phone",
                  name: "phone",
                  component: Phone,
                  meta: {
                    disableScrolling: true
                  }
                },
                {
                  path: "iban",
                  name: "iban",
                  component: Iban,
                  meta: {
                    disableScrolling: true
                  }
                }
              ]
            }
          ]
        },
        {
          path: "/campaigns/:campaign",
          name: "campaigns",
          component: Campaign
        },
        {
          path: "/feed",
          name: "feed",
          component: Feed
        },
        {
          path: "/search-menus-old",
          name: "search-menus-old",
          component: SearchMenu,
          children: [
            {
              path: "venue/:id",
              component: Venue,
              name: "venue"
            }
          ]
        },
        {
          path: "/search-menus",
          name: "search-menus",
          component: SearchMenu2,
          children: [
            {
              path: "venue/:id",
              component: Venue,
              name: "venue"
            }
          ]
        }
      ]
    }
  ]
})
